import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../layouts/page';
import Submit from '../../../components/button';
import { config } from '../../../config';
import SelectField from '../../../components/selectfield';
import Backbtn from '../../../components/backbtn';
import Editor from '../../../components/editor/index';
import ImageUploader from '../../../components/imageuploader';


const Create = () => {
  const navigate = useNavigate(); // To redirect on successful login
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    importance: '',
    image: null
  });
  const [loading, setLoading] = useState(false);
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleFileChange = (name, files) => {
    if (files.length > 0) {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const formDataToSubmit = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== '') { // Only append non-null values
          formDataToSubmit.append(key, value);
      }
    });
       console.log(formDataToSubmit)
    try {
      await axios.post(`${config.api_path}/service-categories`, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success('Service provider created successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary
      
      setFormData({
        name: '',
        description: '',
        importance: '', // Reset as boolean
        image: null
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
      console.error('Submission error:', error);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4>Create Service Provider Category</h4>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <section className='col-md-8'>
                  <div className='row'>
                      <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Name<span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                      </div>
                      <div className="form-group col-md-6">
                      <label className="col-form-label">Group<span>*</span></label>
                      <select
                        className="form-control"
                        name="importance"
                        value={formData.importance} 
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Group</option>
                        <option value="Essential">Essentials</option>
                        <option value="Relevant">Relevant</option>
                        <option value="Desirable">Desirables</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                  <Editor
                    width="col-md-12"
                    label="Description"
                    value={formData.description}
                    onChange={handleEditorChange}
                  />
                  
                  </div>
                </section>
                <ImageUploader
                  title={`Category image`}
                  image={formData.image}
                  onChange={(e) => handleFileChange('image', e.target.files)}
                />
              </div>

              {/* Submit Button */}
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
