import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import Submit from '../../components/button';
import { config } from '../../config';
import Backbtn from '../../components/backbtn'
import ImageUploader from '../../components/imageuploader';

const Create = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone_number:'',
    secondary_mobile:'',
    role_id: '',
    estate_id: '',
    agency_id: '',
    image:null
  });

  const [roles, setRoles] = useState([]);
  const [estates, setEstates] = useState([]);
  const [agency, setAgency] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'Tuwe | Create User';
    const token = localStorage.getItem('auth_token');

    // Fetch roles
    const fetchRoles = async () => {
      try {
        const response = await axios.get(config.api_path + '/roles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setRoles(response.data);
        } else {
          toast.error('No roles found.');
        }
      } catch (error) {
        toast.error('Failed to fetch roles.');
      }
    };

    // Fetch estates
    const fetchEstates = async () => {
      try {
        const response = await axios.get(config.api_path + '/estates', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setEstates(response.data.data);
        } else {
          toast.error('No estates found.');
        }
      } catch (error) {
        toast.error('Failed to fetch estates.');
      }
    };

    // Fetch agencies
    const fetchAgency = async () => {
      try {
        const response = await axios.get(config.api_path + '/agencies', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setAgency(response.data.data);
        } else {
          toast.error('No agency found.');
        }
      } catch (error) {
        toast.error('Failed to fetch agency.');
      }
    };

    fetchRoles();
    fetchEstates();
    fetchAgency();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIDInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: Number(value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('auth_token');
    
    try {
      await axios.post(config.api_path + '/users/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success('User created successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

      setFormData({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role_id: 0,
        estate_id: 0,
        image:null
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create User</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className='col-md-8'>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Name<span>*</span></label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder='Fullname'
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Email<span>*</span></label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder='Email Address'
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Primary Mobile<span>*</span></label>
                        <input
                          type="text"
                          name="phone_number"
                          className="form-control"
                          placeholder='Primary mobile'
                          value={formData.phone_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Secondary Mobile<span>*</span></label>
                        <input
                          type="text"
                          name="secondary_mobile"
                          placeholder='Secondary Mobile'
                          className="form-control"
                          value={formData.secondary_mobile}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      
                  </div>
                  <div className='row'>
                     <div className="form-group col-md-6">
                        <label >User Role<span>*</span></label>
                        <select
                          className="form-control"
                          name="role_id"
                          placeholder="User Role"
                          value={formData.role_id}
                          onChange={handleIDInputChange}
                          required
                        >
                          <option value="">Select Role</option>
                          {roles.length > 0 ? (
                            roles.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No roles available</option>
                          )}
                        </select>
                      </div>
                 {/* Conditionally render Estate based on role */}
                 {(formData.role_id == 3 || formData.role_id === 6 || formData.role_id === 7 )&& (
                  <div className="form-group col-md-6">
                    <label>Estate<span>*</span></label>
                    <select
                      className="form-control"
                      name="estate_id"
                      value={formData.estate_id}
                      onChange={handleIDInputChange}
                      required
                    >
                      <option value="">Select Estate</option>
                      {estates.length > 0 ? (
                        estates.map((estate) => (
                          <option key={estate.id} value={estate.id}>
                            {estate.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>No estates available</option>
                      )}
                    </select>
                  </div>
                )}
                {(formData.role_id === 2 || formData.role_id === 4 || formData.role_id === 5 )&& (
                  <div className="form-group col-md-6">
                    <label>Agency<span>*</span></label>
                    <select
                      className="form-control"
                      name="agency_id"
                      value={formData.agency_id}
                      onChange={handleIDInputChange}
                      required
                    >
                      <option value="">Select Agency</option>
                      {agency.length > 0 ? (
                        agency.map((agency) => (
                          <option key={agency.id} value={agency.id}>
                            {agency.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>No agencies available</option>
                      )}
                    </select>
                  </div>
                )}                            
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Password (leave empty if unchanged)</label>
                  <input
                    type="password"
                    name="password"
                    placeholder='Enter password here'
                    className="form-control"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-6 form-group">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    name="password_confirmation"
                    placeholder='Confirm password here'
                    className="form-control"
                    value={formData.password_confirmation}
                    onChange={handleInputChange}
                  />
                </div> 
                </div>               
              </div>
              <ImageUploader
                  title="Passport Photo"
                  image={formData.image}
                  onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly
                />
                </div>
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
