import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthLayout from '../../layouts/auth';
import { config } from '../../config/';
import isTokenValid from '../../helpers/tokenExpired';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => { 

  useEffect(() => {
    document.title = "Tuwe | Forgot Password";
  }, []);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  if (isTokenValid()) {
    navigate('/dashboard');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(''); 

    try {
      const response = await axios.post(config.api_path + "/forgot-password", {
        email,
      });
      
      // Extract the success message from the response
      const message = response.data.message || 'Reset link sent successfully!';
      toast.success(message); // Display success message
      console.log('reset link sent:', response.data);
    } catch (err) {
      setError('Reset Failed: Invalid email');
      toast.error('Reset Failed: Invalid email'); // Show error toast
      console.log('reset error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <ToastContainer />
      <h3 style={{ textAlign: "center", margin: "20px 0" }}>Forgot Password</h3>
      <p>Enter your email address below and we will send you a link to reset your password</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="label">Email Address <span>*</span></label>
          <div className="input-group">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="mdi mdi-check-circle-outline"></i>
              </span>
            </div>
          </div>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className="form-group">
          <button
            className="btn btn-primary submit-btn btn-block"
            disabled={loading}
            type='submit'
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </div>
        <div className="text-block text-center my-3">
          <span className="text-small font-weight-semibold">Already have an account?</span>
          <Link to="/" className="text-black text-small">Login</Link>
        </div>
      </form>
    </AuthLayout>
  );
}

export default ForgotPassword; // Updated component name to follow convention
