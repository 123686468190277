import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../../../config';
import Spinner from '../../../../components/spinner';
import Backbtn from '../../../../components/backbtn'

const View = () => {
  const [formData, setFormData] = useState({}); // State to hold estate data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const { id } = useParams(); // Get the estate ID from the URL parameters

  // Retrieve the token from localStorage
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Tuwe | Land Listing';

    // Fetch estate data function
    const fetchEstateData = async () => {
      try {
        // Ensure token is available before making the request
        if (!storedToken) {
          toast.error('Authentication token is missing. Please log in again.');
          return;
        }

        const response = await axios.get(`${config.api_path}/house-listings/${id}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`, // Use stored token directly
          },
        });
        const estateData = response.data.data; // Adjust this based on your API response structure
        console.log(estateData); // Debugging output

        // Set form data with fetched estate data
        setFormData(estateData);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch estate data. Please try again.');
        setLoading(false);
      }
    };

    fetchEstateData();
  }, [id, storedToken]); 

  // Function to format keys: Remove underscores and capitalize words
  const formatKey = (key) => {
    return key
      .split('_') // Split the key by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with a space
  };

  return (
    <Layout>
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <h2>{formData.full_name}</h2>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <div className="profile-content">
              <h3 style={{textAlign:'center'}}>House Listed</h3><br/>
              <Backbtn />
              <div className="col-md-12" style={{display:'flex'}}>
                {/* Image Section */}
                
                <div className="col-md-4">
                  <img 
                    src={formData.owner.passport_photo || '/images/placeholder.png'}  // Placeholder image
                    alt={formData.owner.name || 'Profile Image'} 
                    className="profile-image"
                  />

                  {/* About Section (Displayed under the image) */}
                  {formData.owner && (
                    <div className="about-section">
                      <h4>About the Owner</h4>
                      <p>Name: {formData.owner.name}</p>
                      <p>NIN Number: {formData.owner.nin_number}</p>
                      <p>Residence: {formData.owner.residence}</p>
                    </div>
                  )}

                   <div className="about-section">
                   <a href={formData.image} target='_blank' rel='noreferrer'>
                      <button className='btn btn-primary'>View Image</button>
                  </a>
                   </div>


            
                </div>

                {/* Table Section */}
                <div className="col-md-6">
                  <table className="table details-table">
                    <tbody>
                      {/* Displaying estate data from formData while excluding 'id', 'image', and 'about' */}
                      {Object.keys(formData).length > 0 ? (
                        Object.entries(formData)
                          .filter(([key]) => key !== 'id' && key !== 'image' &&
                           key !== 'owner' ) // Exclude 'id', 'image', and 'about'
                          .map(([key, value]) => (
                            <tr key={key}>
                              {/* Format the key and apply styles to control text wrapping */}
                              <td className="detail-key">{formatKey(key)}</td>
                              <td className={`detail-value`}>
                                {value?.toString()}
                              </td>
                            </tr>
                          ))
                          
                      ) : (
                        <tr>
                          <td colSpan="2">No data available</td>
                        </tr>
                      )}
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

           
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default View;
