import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../../config';
import Spinner from '../../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../../components/pagination'
import utils from '../../../helpers/textEdit';
import Backbtn from '../../../components/backbtn';

const Index = () => {
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const itemsPerPage = 10; // Define how many items to display per page

  useEffect(() => {
    document.title = "Tuwe | Service Providers";
    fetchCategories(currentPage);
  }, [currentPage]);
  
  const escapeHtmlTags = (text) => {
     return text.replace(/<\/?p>/g, '');
   };
  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/service-categories?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("sp",response.data.data)
      setCategories(response.data.data);
      setTotal(response.data.meta.total);
      setLoading(false);
    } catch (error) {
      console.error("error",error); 
      toast.error('Failed to fetch categories. Please try again.');
      setLoading(false);
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Service Provider Categories</h3>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to="/service-provider/category/create">
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Category
              </button>
            </Link>
            {loading ? (
              <Spinner />
            ) : (
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Group</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((cat,index )=> (
                    <tr key={cat.id}>
                      <td>{index+1}</td>
                      <td>
                            <img className="img-xs rounded-circle" src={cat.image} alt="category" />
                      </td>
                      <td><Link to={`/service-provider/subcategory/${cat.id}`}>{utils.shorten_string(cat.name, 30)}</Link></td>
                      <td>{utils.shorten_string(escapeHtmlTags(cat.description), 40)}</td>
                      <td>{cat.importance}</td>
                      <td>
                        <Dropdown serviceId={cat.id} onDelete={() => fetchCategories(currentPage)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}
                <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
