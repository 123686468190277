import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../layouts/page';
import { config } from '../../../config';
import Submit from '../../../components/button';

import FormField from '../../../components/formfield';
import SelectField from '../../../components/selectfield';
import ImageUploader from '../../../components/imageuploader';
import useFetch from '../../../helpers/fetch';
import Backbtn from '../../../components/backbtn';
import Editor from '../../../components/editor';

const Create = () => {
  const { id } = useParams();
  const estateID = localStorage.getItem('estateID');
  const [formData, setFormData] = useState({
    estate_id: estateID,
    type: '',
    service_sub_category_id: id,
    business_name: '',
    certificate_of_registration: '',
    business_tin: '',
    nin_number: '',
    district_id: '',
    constituency_id: '',
    subcounty_id: '',
    parish_id: '',
    village_id: '',
    street: '',
    plot_number: '',
    telephone_number: '',
    description:'',
    image: null,
    logo: null,
    passport_photo: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

   // Fetching data
   const { data: districts = [], loading: loadingDistricts } = useFetch(`${config.api_path}/districts`,0);
   const { data: constituencies = [], loading: loadingConstituencies } = useFetch(
    formData.district_id ? `${config.api_path}/districts/${formData.district_id}/constituencies` : null,0
  );
  const { data: subConstituencies = [], loading: loadingSubConstituencies } = useFetch(
    formData.constituency_id ? `${config.api_path}/constituencies/${formData.constituency_id}/subcounties` : null,0
  );
  const { data: parishes = [], loading: loadingParishes } = useFetch(
    formData.subcounty_id ? `${config.api_path}/subcounties/${formData.subcounty_id}/parishes` : null,0
  );
  const { data: villages = [], loading: loadingVillages } = useFetch(
    formData.parish_id ? `${config.api_path}/parishes/${formData.parish_id}/villages` : null,0
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'district_id') {
      setFormData((prev) => ({
        ...prev,
        constituency_id: '',
        subcounty_id: '',
        parish_id: '',
        village_id: '',
      }));
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const formDataToSubmit = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if(value){
         formDataToSubmit.append(key, value);
      }
    });
    console.log(formDataToSubmit)
    try {
      await axios.post(`${config.api_path}/service-providers`, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Service provider created successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create Service Provider</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <section className='col-md-8'>
                    <div className='row'>
                        <FormField
                          width="col-md-6"
                          label="Business Name"
                          name="business_name"
                          value={formData.business_name}
                          onChange={handleInputChange}
                          required
                        />
                        <FormField
                          width="col-md-6"
                          label="Telephone Number"
                          name="telephone_number"
                          value={formData.telephone_number}
                          onChange={handleInputChange}
                          required
                        />
                    </div>
                    <div className='row'>
                        <div className="form-group col-md-6">
                          <label>Type</label>
                          <select
                            name="type"
                            className="form-control"
                            value={formData.type}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select Type</option>
                            <option value="formal">Formal</option>
                            <option value="informal">Informal</option>
                          </select>
                        </div>
                          { formData.type =="formal"? 
                                <FormField
                                  width="col-md-6"
                                  label="Certificate of Registration"
                                  name="certificate_of_registration"
                                  value={formData.certificate_of_registration}
                                  onChange={handleInputChange}
                                  required
                                />:""}
                            { formData.type =="formal"? 
                                <FormField
                                    width="col-md-6"
                                    label="Plot Number"
                                    name="plot_number"
                                    value={formData.plot_number}
                                    onChange={handleInputChange}
                                    required
                                />:""
                          }
                          { formData.type =="informal"? 
                            <FormField
                              width="col-md-6"
                              label="Owners NIN Number"
                              name="nin_number"
                              value={formData.nin_number}
                              onChange={handleInputChange}
                            />:""
                          }
           
                    { formData.type =="formal"? 
                          <FormField
                            width="col-md-6"
                            label="Business TIN"
                            name="business_tin"
                            value={formData.business_tin}
                            onChange={handleInputChange}
                            required
                          />:""
                    }
                   
                    </div>
                    <div className="row">
                        <SelectField
                            width="col-md-6"
                            label="District"
                            name="district_id"
                            value={formData.district_id}
                            onChange={handleInputChange}
                            options={districts}
                            loading={loadingDistricts}
                            required
                          />
                          <SelectField
                            width="col-md-6"
                            label="County"
                            name="constituency_id"
                            value={formData.constituency_id}
                            onChange={handleInputChange}
                            options={constituencies}
                            loading={loadingConstituencies}
                            required
                            disabled={!formData.district_id}
                          />
                    </div>
                    <div className="row">
                      <SelectField
                          width="col-md-6"
                          label="Sub County"
                          name="subcounty_id"
                          value={formData.subcounty_id}
                          onChange={handleInputChange}
                          options={subConstituencies}
                          loading={loadingSubConstituencies}
                          required
                          disabled={!formData.constituency_id}
                        />
                      <SelectField
                          width="col-md-6"
                          label="Parish"
                          name="parish_id"
                          value={formData.parish_id}
                          onChange={handleInputChange}
                          options={parishes}
                          loading={loadingParishes}
                          required
                          disabled={!formData.subcounty_id}
                        />
                      </div>
              <div className="row">
                  <SelectField
                    width="col-md-6"
                    label="Village"
                    name="village_id"
                    value={formData.village_id}
                    onChange={handleInputChange}
                    options={villages}
                    loading={loadingVillages}
                    required
                    disabled={!formData.parish_id}
                  />
                  <FormField
                      width="col-md-6"
                      label="Street"
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      required
                  />
              </div>
              <div className='row'>
                  <Editor
                    width="col-md-12"
                    label="Description"
                    value={formData.description}
                    onChange={handleEditorChange}
                  />
              </div>
            </section>
            <div className='col-md-4'>
                      <ImageUploader
                        width="col-md-12"
                        title={`Business image`}
                        image={formData.image}
                        onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly

                      /><br/>
                    { formData.type =="formal"? 
                     <ImageUploader
                        width="col-md-12"
                        title={`Business logo`}
                        image={formData.logo}
                        onChange={(e) => setFormData((prev) => ({ ...prev, logo: e.target.files[0] }))} // Change to handle the file directly
                      />:""
                    }
                    { formData.type =="informal"? 
                      <ImageUploader
                        width="col-md-12"
                        title={`Owners Passport Photo`}
                        image={formData.passport_photo}
                        onChange={(e) => setFormData((prev) => ({ ...prev, passport_photo: e.target.files[0] }))} // Change to handle the file directly

                      />:""
                    }
            </div>
          </div>
              <div className="row">
                <div className="col-md-12">
                  <Submit loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Create;
