import { React, useEffect, useState} from 'react'
import AuthLayout from '../../layouts/auth'
import axios from 'axios'
import { config } from '../../config';
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

const Reset_password = () => {
  const navigate = useNavigate(); // To redirect on successful login

  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token")
  const email = queryParameters.get("email")

  //send  password, confirm_password, emaol, token

  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(''); // Clear any previous errors

    try {
      const response = await axios.post(config.api_path + "/reset-password", {
        email,
        password,
        password_confirmation,
        token,
      });

      // Redirect to the dashboard or any protected route
      
      toast.success(response.data);
      setTimeout('', 1000);
      navigate('/');
    } catch (err) {
      setError('Login Failed: Invalid email or password');
      toast.error('Reset Failed: Invalid token');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    document.title = "Tuwe | Login"
  }, []);
  

  useEffect(() => {
    document.title = "Tuwe | Reset Password"
  }, []);
  return (
    <AuthLayout>
       <h3 style={{textAlign:"center", margin:"20px 0"}} >Reset Password</h3>
        <form  onSubmit={handleSubmit}>
        <div class="form-group">
          <label class="label">Password<span>*</span></label>
          <div class="input-group">
            <input type="password" class="form-control" placeholder="Password"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="mdi mdi-check-circle-outline"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="label">Confirm Password<span>*</span></label>
          <div class="input-group">
            <input type="password" class="form-control" name="password_confirmation" placeholder="Confirm Password"
               value={password_confirmation}
               onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="mdi mdi-check-circle-outline"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary submit-btn btn-block">Submit</button>
        </div>
        <ToastContainer />
        </form>
    </AuthLayout>
  )
}

export default Reset_password