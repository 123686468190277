import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../../config/index';
import Modal from '../../../components/modal';

const Index = ({ estates, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`#dropdown-${estates.id}`)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [estates.id]);

  const handleDelete = async () => {
    const token = localStorage.getItem('auth_token');
    setIsDeleting(true);

    try {
      const response = await axios.delete(`${config.api_path}/estates/${estates.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success('Estate deleted successfully!');
        onDelete(estates.id);
      } else {
        toast.error('Failed to delete estate. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting estate:', error);
      toast.error('An error occurred while deleting the estate.');
    } finally {
      setIsDeleting(false);
      toggleModal(); // Close the modal after deletion attempt
    }
  };

  return (
    <>
      <Modal toggleModal={toggleModal}
             showModal={showModal} 
             handleDelete={handleDelete} 
             title="estate" 
      />
       
      <div className="dropdown" id={`dropdown-${estates.id}`} style={{ position: 'relative' }}>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          Actions
        </button>
        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ position: 'absolute', top: '100%', zIndex: 1 }}>
          <Link className="dropdown-item" to={`/estates/${estates.id}/edit`}>Edit</Link>
          <button
            className="dropdown-item"
            onClick={toggleModal}
            disabled={isDeleting}
          >
           Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default Index;
