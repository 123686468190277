import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../../../config';
import Spinner from '../../../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../../../components/pagination'
import Backbtn from '../../../../components/backbtn'
import utils from '../../../../helpers/textEdit'

const Index = () => {
  const { pid} = useParams()
  const [Land, setLand] = useState([]);
  const [Agency, setAgency] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const itemsPerPage = 15; // Define how many items to display per page

  useEffect(() => {
    document.title = "Tuwe | Land Listings";
    fetchLand(currentPage);
    fetchAgency();
  }, [currentPage]);
  
  const fetchAgency = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/agencies/${pid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data)
      setAgency(response.data.data);
    } catch (error) {
      console.error(error); 
      toast.error('Failed to fetch Agency. Please try again.');
      setLoading(false);
    }
  };
  const fetchLand = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/land-listings?agency_id=${pid}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data)
      setLand(response.data.data);
      setTotal(response.data.data.length);
      setLoading(false);
    } catch (error) {
      console.error(error); 
      toast.error('Failed to fetch Land. Please try again.');
      setLoading(false);
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>{Agency.name} Land Listings</h3>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to={'/property-agency/'+pid+'/Land-listed/create'}>
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Land Listing
              </button>
            </Link>

            {loading ? (
              <Spinner />
            ) : (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <td></td>
                    <th>Owner</th>
                    
                    <th>Estate Name</th>
                    <th>Category</th>
                    <th>Land Size</th>
                    <th>Projected Cost(Ugx)</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Land.map(boda => (
                    <tr key={boda.id}>
                      <td>{boda.id}</td>
                      <th><img class="img-xs rounded-circle" src={boda.image} alt="estate"/></th>
                      <td><Link to={`/property-agency/Land-listed/${boda.id}`}>{utils.shorten_string(boda.owner.name,15)}</Link></td>                      
                      <td>{utils.shorten_string(boda.estate_name,10)}</td>
                      <td>{boda.category}</td>
                      <td>{boda.land_size}</td>
                      <td><span className='price'>{ utils.formatPrice(boda.projected_cost)}</span></td>
                      <td>{utils.statusDisplay(boda.status)}</td>
                      <td>
                        <Dropdown estateId={boda.id} pid={pid} onDelete={() => fetchLand(currentPage)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
