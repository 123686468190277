import React from 'react'
import { useNavigate } from 'react-router-dom'

const Index = () => {
    const navigate = useNavigate(); // Initialize navigate
    return (
    <button
    className="btn btn-outline-primary col-md-1"
    style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
    onClick={() => navigate(-1)}
  >
    <i className="fas fa-arrow-left" style={{ marginRight: '5px' }}></i>
    Back
  </button>
  )
}

export default Index