import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner'
import Pagination from '../../components/pagination'
import Service from './services'
import Dropdown from './dropdown'
import utils from '../../helpers/textEdit';


const Index = () => {
  // State to store estate data
  const [agency, setAgency] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading
  const [totalPages, setTotalPages] = useState(0); // State to track which dropdown is open
  const [currentPage, setCurrentPage] = useState(1);
  // Calculate the total number of pages

  const token = localStorage.getItem('auth_token');

  const getAgency =()=>{
    axios
    .get(config.api_path+'/estate-agencies', {
      headers: {
        Authorization: `Bearer ${token}`, // Include auth token in headers
      },
    })
    .then((response) => {
      setAgency(response.data.data); 
      setTotalPages(response.data.meta.last_page);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false); // Stop loading after data is fetched
    })
    .catch((error) => {
      toast.error('Failed to fetch estate agents. Please try again.');
      setLoading(false); // Stop loading on error
    });
  }

  // Fetch estate data when component mounts
  useEffect(() => {
    document.title = "Tuwe | Estate Agency";
    setLoading(true); // Start loading
    getAgency();
  }, [token]);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Estate Agencies</h3>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                  {"Viewing page " + currentPage + " of " + totalPages + " pages"}
                </span>
            <Link to="/estate-agencies/create">
            <button
              type="button"
              style={{ marginBottom: '10px',float:'right' }}
              className="btn btn-outline-primary"
            >
              New Agency
            </button>
           </Link>
            {/* Spinner while loading data */}
            {loading ? (
             <Spinner />
            ) : (
              <>
              <div className="table-responsive">

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Agency Name</th>
                    <th>Phone</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {agency.map(boda => (
                    <tr key={boda.id}>
                      <td>{boda.id}</td>
                      <td></td>
                      <td><Link to={`/estate-agencies/${boda.id}`}>{utils.capitalizeWords(utils.shorten_string(boda.name,20))}</Link></td>
                      <td>{boda.phone}</td>
                      <td>{new Date(boda.created_at).toLocaleDateString()}</td>
                      <td>{ utils.statusDisplay(boda.status) }</td>
                      <td>
                        <Dropdown serviceId={boda.id} onDelete={() => getAgency(currentPage)} />
                      </td>
                      <td>
                        <Service estates={boda} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
            </>
             )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
