import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../../config/index'
import Modal from '../../../components/modal';

// Custom Dropdown Component
const Index = ({ serviceId, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
 
  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  // Function to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`#dropdown-${serviceId}`)) {
        setIsOpen(false);
      }
    };

    // Add event listener to close dropdown on outside click
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [serviceId]);

  // Function to handle deletion
  const handleDelete = async () => {
      const token = localStorage.getItem('auth_token'); // Assuming you stored the token in local storage
      try {
        const response = await axios.delete(config.api_path+`/agencies/${serviceId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token in the Authorization header
          },
        });

        if (response.status === 200) {
          toast.success('Agency deleted successfully!'); // Show success toast
          onDelete(serviceId); // Call the onDelete function passed from the parent
        } else {
          toast.error('Failed to delete agency. Please try again.'); // Show error toast
        }
      } catch (error) {
        console.error('Error deleting agency:', error);
        toast.error('An error occurred while deleting the agency.'); // Show error toast
    }
  };

  return (
    <>
    <Modal toggleModal={toggleModal}
           showModal={showModal} 
           handleDelete={handleDelete} 
           title="estate agency" 
    />
    <div className="dropdown" id={`dropdown-${serviceId}`} style={{ position: 'relative' }}>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        Actions
      </button>
      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', position: 'absolute', top: '100%', zIndex: 1 }}>
        <Link className="dropdown-item" to={`/property-agencies/${serviceId}/edit`}>Edit</Link>
        <button className="dropdown-item"
         onClick={toggleModal}
         disabled={isDeleting}
         >
          Delete
        </button>
      </div>
    </div>
    </>
  );
};

export default Index;
