import React from 'react';


const SelectField = ({ label, name, width="col-md-4", value, onChange, options, loading, disabled, required }) =>{
  
return  (
  <div className={`form-group ${width}`}>
    <label >
      {label}
      {required && <span>*</span>}
    </label>
    <select
      className="form-control"
      name={name}
      value={value} // Use value here to manage selected option
      onChange={onChange}
      disabled={disabled}
      required={required}
    >
      <option value="">{`Select  ${label}`}</option>
      {loading ? (
        <option>Loading...</option>
      ) : (
        Array.isArray(options)? options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        )):<option>"no data "</option>
      )}
    </select>
  </div>
  )
}

export default SelectField;
