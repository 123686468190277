import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';

const UserView = () => {
  const [userData, setUserData] = useState({}); // State to hold user data
  const [loading, setLoading] = useState(true); // State to manage loading state

  // Retrieve the token from localStorage
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Tuwe | Profile';

    // Fetch user data function
    const fetchUserData = async () => {
      try {
        // Ensure token is available before making the request
        if (!storedToken) {
          toast.error('Authentication token is missing. Please log in again.');
          return;
        }
        const response = await axios.get(`${config.api_path}/profile`, {
          headers: {
            Authorization: `Bearer ${storedToken}`, // Use stored token directly
          },
        });
        const userData = response.data; // Adjust this based on your API response structure
        console.log(userData)
        // Set user data with fetched data
        setUserData(userData);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch user data. Please try again.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [storedToken]); // Add `storedToken` as a dependency

  // Function to format keys: Remove underscores and capitalize words
  const formatKey = (key) => {
    return key
      .split('_') // Split the key by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with a space
  };

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4>{userData.name}</h4>
            {loading ? (
              <Spinner />
            ) : (
              <div style={{ overflowX: 'auto' }}>
                <table className="table table-bordered" style={{ width: '100%' }}>
                  <tbody>
                    {/* Displaying user data from userData while excluding 'id' */}
                    {Object.keys(userData).length > 0 ? (
                      Object.entries(userData)
                        .filter(([key]) => key !== 'id') // Exclude the 'id' field
                        .map(([key, value]) => (
                          <tr key={key}>
                            {/* Format the key and apply styles to control text wrapping */}
                            <td className="text-container" style={{ fontWeight: 'bold' }}>{formatKey(key)}</td>
                            <td
                              className={`text-container ${key === 'about' ? 'about-container' : ''}`}
                              style={key === 'about' ? { whiteSpace: 'pre-wrap', padding: '20px', overflow: 'visible' } : {}}
                            >
                              {value?.toString()}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="2">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default UserView;
