import React from 'react'
import  SuperDash  from './tuwe'
import EstatesDash from './estates'
import PropertyDash from './property_agency'

const index = () => {
   
    const userrole = localStorage.getItem('role');
   
  return (
     (userrole =='Super Admin')? <SuperDash />: 
             (userrole =='Estate Manager')? <EstatesDash />:
                     (userrole =='Property Agent')? <PropertyDash />:''
  )
}

export default index