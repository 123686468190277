import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../../components/spinner';
import { config } from '../../../config'; // If you have config.js for constants or settings
import Backbtn from '../../../components/backbtn';

const View = () => {
  const [formData, setFormData] = useState({}); // State to hold service provider data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const { id } = useParams(); // Get the service provider ID from the URL parameters

  // Retrieve the token from localStorage
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Service Provider Details';

    // Fetch service provider data function
    const fetchServiceProviderData = async () => {
      try {
        // Ensure token is available before making the request
        if (!storedToken) {
          toast.error('Authentication token is missing. Please log in again.');
          return;
        }

        const response = await axios.get(config.api_path+`/service-providers/${id}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`, // Use stored token directly
          },
        });
        const serviceProviderData = response.data.data; // Adjust this based on your API response structure
        console.log(serviceProviderData); // Debugging output

        // Set form data with fetched service provider data
        setFormData(serviceProviderData);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch service provider data. Please try again.');
        setLoading(false);
      }
    };

    fetchServiceProviderData();
  }, [id, storedToken]); // Add `storedToken` as a dependency

  // Function to format keys: Remove underscores and capitalize words
  const formatKey = (key) => {
    return key
      .split('_') // Split the key by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with a space
  };

  return (
    <Layout>
    <div className="container">
      <div className="profile-card">
        <div className="profile-header">
          <h3 style={{textAlign:'center'}}>{formData.business_name}</h3>
          <Backbtn />
        </div>
          <div className="profile-content">
            <div className="profile-info">
              {/* Image Section */}
              <div className="image-section">
                <img 
                  src={formData.image || '/images/placeholder.jpg'}  // Placeholder image
                  alt={formData.name || 'Profile Image'} 
                  className="profile-image"
                />
                {/* About Section (Displayed under the image) */}
                {formData.address && (
                  <div className="about-section">
                    <h3>Address</h3>
                    <p>{formData.address}</p>
                  </div>
                )}
          </div>
              {/* Table Section */}
              <div className="details-section">
                <table className="table details-table">
                  <tbody>
                    {/* Displaying estate data from formData while excluding 'id', 'image', and 'about' */}
                    {Object.keys(formData).length > 0 ? (
                      Object.entries(formData)
                        .filter(([key]) => key !== 'id' && key !=='image' && key !=='service_sub_category_id')
                        .map(([key, value]) => (
                          <tr key={key}>
                            {/* Format the key and apply styles to control text wrapping */}
                            <td className="detail-key">{formatKey(key)}</td>
                            <td className={`detail-value`}>
                            {Array.isArray(value) 
                                    ? value.map(item => item.name || item).join(", ") 
                                    : typeof value === 'object' && value !== null 
                                        ? value.name || JSON.stringify(value) 
                                        : value
                                }
                            </td>
                          </tr>
                        )) 
                    ):(
                      <tr>
                        <td colSpan="2">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
    <ToastContainer />
  </Layout>
  );
};

export default View;
