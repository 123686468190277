import { useEffect, React} from 'react'
import Layout from '../../layouts/page'

const Index = () => {

  useEffect(() => {
    document.title = "Tuwe | Settings"
  }, []);
  return (
    <Layout>
    <div class="col-lg-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-body">
      <h3>Settings</h3>
      
    </div>
  </div>
</div>
</Layout>
  )
}

export default Index