import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Backbtn from '../../components/backbtn'

const View = () => {
  const [formData, setFormData] = useState({}); // State to hold estate data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const { id } = useParams(); // Get the estate ID from the URL parameters

  // Retrieve the token from localStorage
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Tuwe | Property Agency';

    // Fetch estate data function
    const fetchAgencyDetails = async () => {
      try {
        const token = localStorage.getItem('auth_token'); // Retrieve the auth token
        const response = await axios.get(`${config.api_path}/agencies/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token in the request header
          },
        });
        console.log(response.data.data)
        const { name, email, phone, address, city, country, type, logo, created_at} = response.data.data;
        setFormData({ name, email, phone, address, city, country, type, logo, created_at});
      } catch (error) {
        toast.error('Failed to load agency details.');
      }
    };

    fetchAgencyDetails();
  }, [id, storedToken]); 

  // Function to format keys: Remove underscores and capitalize words
  const formatKey = (key) => {
    return key
      .split('_') // Split the key by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with a space
  };

  return (
    <Layout>
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <h3 style={{textAlign:'center'}}>{formData.name}</h3>
            <Backbtn />
          </div>
            <div className="profile-content">
              <div className="profile-info">
                {/* Image Section */}
                <div className="image-section">
                  <img 
                    src={formData.logo || '/images/placeholder.jpg'}  // Placeholder image
                    alt={formData.name || 'Profile Image'} 
                    className="profile-image"
                  />
                  {/* About Section (Displayed under the image) */}
                  {formData.address && (
                    <div className="about-section">
                      <h3>Address</h3>
                      <p>{formData.address}</p>
                    </div>
                  )}
            </div>
                {/* Table Section */}
                <div className="details-section">
                  <table className="table details-table">
                    <tbody>
                      {/* Displaying estate data from formData while excluding 'id', 'image', and 'about' */}
                      {Object.keys(formData).length > 0 ? (
                        Object.entries(formData)
                          .filter(([key]) => key !== 'id' && key !=='address' && key !=='logo')
                          .map(([key, value]) => (
                            <tr key={key}>
                              {/* Format the key and apply styles to control text wrapping */}
                              <td className="detail-key">{formatKey(key)}</td>
                              <td className={`detail-value`}>
                                {value?.toString()}
                              </td>
                            </tr>
                          )) 
                      ):(
                        <tr>
                          <td colSpan="2">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default View;
