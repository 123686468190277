import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import { config } from '../../config/index';
import Submit from '../../components/button';
import FormField from '../../components/formfield';
import SelectField from '../../components/selectfield';
import useFetch from '../../helpers/fetch';
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';

const Create = () => {
  const { id } = useParams();
  const estateID = localStorage.getItem('estateID');
  const [formData, setFormData] = useState({
    full_name: '',
    call_name: '',
    boda_stage_id: id,
    district_id: '',
    constituency_id: '',
    subcounty_id: '',
    parish_id: '',
    village_id: '',
    nin_number: '',
    number_plate: '',
    telephone_number: '',
    estate_id: estateID,
    passport_photo: null,
    local_council_letter: null,
    community_police_letter: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Tuwe | Create Estate Boda";
  }, []);

  const handleFileChange = (name, files) => {
    if (files.length > 0) {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    }
  };

  const { data: districts = [], loading: loadingDistricts } = useFetch(`${config.api_path}/districts`, 0);
  const { data: constituencies = [], loading: loadingConstituencies } = useFetch(
    formData.district_id ? `${config.api_path}/districts/${formData.district_id}/constituencies` : null, 0
  );
  const { data: subConstituencies = [], loading: loadingSubConstituencies } = useFetch(
    formData.constituency_id ? `${config.api_path}/constituencies/${formData.constituency_id}/subcounties` : null, 0
  );
  const { data: parishes = [], loading: loadingParishes } = useFetch(
    formData.subcounty_id ? `${config.api_path}/subcounties/${formData.subcounty_id}/parishes` : null, 0
  );
  const { data: villages = [], loading: loadingVillages } = useFetch(
    formData.parish_id ? `${config.api_path}/parishes/${formData.parish_id}/villages` : null, 0
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const formDataToSubmit = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (value) {
        formDataToSubmit.append(key, value);
      }
    });

    try {
      await axios.post(`${config.api_path}/estate-bodas`, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Estate Boda created successfully!');
      setTimeout(() => {
        navigate(-1);
      }, 2000);
      
      // Reset form after submission
      setFormData({
        full_name: '',
        call_name: '',
        boda_stage_id: id,
        district_id: '',
        constituency_id: '',
        subcounty_id: '',
        parish_id: '',
        village_id: '',
        nin_number: '',
        number_plate: '',
        telephone_number: '',
        estate_id: estateID,
        passport_photo: null,
        local_council_letter: null,
        community_police_letter: null,
      });
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error submitting form');
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4>Create Estate Boda</h4>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <FormField label="Full Name" name="full_name" value={formData.full_name} onChange={handleInputChange} required />
                <FormField label="Call Name" name="call_name" value={formData.call_name} onChange={handleInputChange} required />
                <SelectField
                  label="District"
                  name="district_id"
                  value={formData.district_id}
                  onChange={handleInputChange}
                  options={districts}
                  loading={loadingDistricts}
                  required
                />
              </div>
              <div className="form-row">
                <SelectField
                  label="County"
                  name="constituency_id"
                  value={formData.constituency_id}
                  onChange={handleInputChange}
                  options={constituencies}
                  loading={loadingConstituencies}
                  required
                  disabled={!formData.district_id}
                />
                <SelectField
                  label="Sub-County"
                  name="subcounty_id"
                  value={formData.subcounty_id}
                  onChange={handleInputChange}
                  options={subConstituencies}
                  loading={loadingSubConstituencies}
                  required
                  disabled={!formData.constituency_id}
                />
                <SelectField
                  label="Parish"
                  name="parish_id"
                  value={formData.parish_id}
                  onChange={handleInputChange}
                  options={parishes}
                  loading={loadingParishes}
                  required
                  disabled={!formData.subcounty_id}
                />
              </div>
              <div className="form-row">
                <SelectField
                  label="Village"
                  name="village_id"
                  value={formData.village_id}
                  onChange={handleInputChange}
                  options={villages}
                  loading={loadingVillages}
                  required
                  disabled={!formData.parish_id}
                />
                <FormField label="NIN Number" name="nin_number" value={formData.nin_number} onChange={handleInputChange} required />
                <FormField label="Number Plate" name="number_plate" value={formData.number_plate} onChange={handleInputChange} required />
              </div>
              <div className="form-row">
                <FormField label="Telephone Number" name="telephone_number" value={formData.telephone_number} onChange={handleInputChange} required />
              </div>
              <div className='form-row'>
                <ImageUploader
                  title="Passport Photo"
                  image={formData.passport_photo}
                  onChange={(e) => handleFileChange('passport_photo', e.target.files)}
                  required
                />
                <ImageUploader
                  title="Local Council Letter"
                  image={formData.local_council_letter}
                  onChange={(e) => handleFileChange('local_council_letter', e.target.files)}
                  required
                />
                <ImageUploader
                  title="Community Police Letter"
                  image={formData.community_police_letter}
                  onChange={(e) => handleFileChange('community_police_letter', e.target.files)}
                  required
                />
              </div>
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
