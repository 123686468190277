import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Backbtn from '../../components/backbtn';

const View = () => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedArticles, setExpandedArticles] = useState({}); // Track expanded articles
  const { id } = useParams();
  
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Tuwe | Estate';

    const fetchEstateData = async () => {
      try {
        if (!storedToken) {
          toast.error('Authentication token is missing. Please log in again.');
          return;
        }

        const response = await axios.get(`${config.api_path}/estates/${id}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const estateData = response.data.data;
        setFormData(estateData);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch estate data. Please try again.');
        setLoading(false);
      }
    };

    fetchEstateData();
  }, [id, storedToken]);

  const formatKey = (key) => {
    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const escapeHtmlTags = (text) => {
    return text.replace(/<\/?p>/g, '');
  };
  
  // Toggle the visibility of an article's content
  const toggleArticleContent = (index) => {
    setExpandedArticles((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the visibility of the article content
    }));
  };

  return (
    <Layout>
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <h3 style={{ textAlign: 'center' }}>{formData.name}</h3>
            <Backbtn />
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="profile-content">
              <div className="profile-info">
                <div className="image-section">
                  <img
                    src={formData.image || '/images/placeholder.png'}
                    alt={formData.name || 'Profile Image'}
                    className="profile-image"
                    width="100%"
                  />
                  {formData.about && (
                    <div className="about-section">
                      <div dangerouslySetInnerHTML={{ __html: formData.about }} />
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <table className="table details-table">
                    <tbody>
                      {Object.keys(formData).length > 0 ? (
                        Object.entries(formData)
                          .filter(([key]) => key !== 'id' && key !== 'image'
                           && key !== 'about' && key !== 'articles'&& key !== 'updated_at')
                          .map(([key, value]) => (
                            <tr key={key}>
                              <td className="detail-key">{formatKey(key)}</td>
                              <td className="detail-value">
                                {Array.isArray(value)
                                  ? value.map(item => item.name || item).join(', ')
                                  : typeof value === 'object' && value !== null
                                  ? value.name || JSON.stringify(value)
                                  : value}
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="2">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {formData.articles && formData.articles.length > 0 && (
                  <div className="articles-content">
                    <h5>Articles</h5>
                    <div className="articles-list">
                      {formData.articles.map((article, index) => (
                        <div key={index} className="article-item">
                          <button
                            onClick={() => toggleArticleContent(index)}
                            className="btn btn-outline-primary"
                            style={{width: '100%'}}
                          >
                            {article.title || `Article ${index + 1}`}
                          </button>
                          {expandedArticles[index] && (
                            <div className="article-content">
                              <p>{escapeHtmlTags(article.content)}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                </div>

                {/* Always Visible Articles Section */}
              
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default View;
