import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../../config/index'

// Custom Dropdown Component
const Index = ({ member, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
 console.log("member in side compo",member)
  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`#dropdown-${member.id}`)) {
        setIsOpen(false);
      }
    };

    // Add event listener to close dropdown on outside click
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [member.id]);

  // Function to handle deletion
  const handleDelete = async () => {
    const confirmed = window.confirm('Action not permitted?');
    if (confirmed) {
      const token = localStorage.getItem('auth_token'); // Assuming you stored the token in local storage
      // try {
      //   const response = await axios.delete(config.api_path+`/estates/member/${member.id}`, {
      //     headers: {
      //       Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      //     },
      //   });

      //   if (response.status === 200) {
      //     toast.success('Estate member deleted successfully!'); // Show success toast
      //     onDelete(member.id); // Call the onDelete function passed from the parent
      //   } else {
      //     toast.error('Failed to delete estate member. Please try again.'); // Show error toast
      //   }
      // } catch (error) {
      //   console.error('Error deleting estate:', error);
      //   toast.error('An error occurred while deleting the estate.'); // Show error toast
      // }
    }
  };

  return (
    <div className="dropdown" id={`dropdown-${member.id}`} style={{ position: 'relative' }}>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        Actions
      </button>
      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', position: 'absolute', top: '100%', zIndex: 1 }}>
        <button className="dropdown-item" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default Index;
