import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './helpers/protectedRoute';
import Login from './pages/authentication/login';
import ForgotPassword from './pages/authentication/forgot_password';
import ResetPassword from './pages/authentication/reset_password';

import Dashboard from './pages/dashboard/index';
import Settings from './pages/settings';
// audit trail

// roles crude
import Support from './pages/support';

import Users from './pages/users';
import CreateUsers from './pages/users/create';
import EditUsers from './pages/users/edit';
import ReadUsers from './pages/users/view';
import Profile from './pages/authentication/profile'

import Estates from './pages/estate';
import EstateMembers from './pages/estate_member/index'
import ViewEstateMembers from './pages/estate_member/view'
import CreateEstate from './pages/estate/create';
import EditEstate from './pages/estate/edit';
import ReadEstate from './pages/estate/view';

import PropertyLand from './pages/property_agent/digital_broker/land/index';
import CreatePropertyLand from './pages/property_agent/digital_broker/land/create';
import EditPropertyLand from './pages/property_agent/digital_broker/land/edit';
import ReadPropertyLand from './pages/property_agent/digital_broker/land/view';

import PropertyHouses from './pages/property_agent/digital_broker/houses';
import CreatePropertyHouses from './pages/property_agent/digital_broker/houses/create';
import EditPropertyHouses from './pages/property_agent/digital_broker/houses/edit';
import ReadPropertyHouses from './pages/property_agent/digital_broker/houses/view';

import EstateLand from './pages/estate_agent/digital_broker/land/index';
import CreateEstateLand from './pages/estate_agent/digital_broker/land/create';
import EditEstateLand from './pages/estate_agent/digital_broker/land/edit';
import ReadEstateLand from './pages/estate_agent/digital_broker/land/view';

import EstateHouses from './pages/estate_agent/digital_broker/houses';
import CreateEstateHouses from './pages/estate_agent/digital_broker/houses/create';
import EditEstateHouses from './pages/estate_agent/digital_broker/houses/edit';
import ReadEstateHouses from './pages/estate_agent/digital_broker/houses/view';

import EstateAgencies from './pages/estate_agent';
import CreateEstateAgencies from './pages/estate_agent/create';
import EditEstateAgencies from './pages/estate_agent/edit';
import ReadEstateAgencies from './pages/estate_agent/view';

import PropertyStaff from './pages/property_agent/staff/'
import AgencyStaff from './pages/estate_agent/staff/'

import EstateBodaStage from './pages/estate_boda_stage';
import CreateEstateBodaStage from './pages/estate_boda_stage/create';
import EditEstateBodaStage from './pages/estate_boda_stage/edit';
import ReadEstateBodaStage from './pages/estate_boda_stage/view';

import EstateBoda from './pages/estate_boda';
import CreateEstateBoda from './pages/estate_boda/create';
import EditEstateBoda from './pages/estate_boda/edit';
import ReadEstateBoda from './pages/estate_boda/view';

import EstateNoticeboard from './pages/estate_noticeboard';
import CreateEstateNoticeboard from './pages/estate_noticeboard/create';
import EditEstateNoticeboard from './pages/estate_noticeboard/edit';
import ReadEstateNoticeboard from './pages/estate_noticeboard/view';

import EstatePay from './pages/estate_pay';
import EditEstatePay from './pages/estate_pay/edit';
import ReadEstatePay from './pages/estate_pay/view';

import PropertyAgent from './pages/property_agent';
import CreatePropertyAgent from './pages/property_agent/create';
import EditPropertyAgent from './pages/property_agent/edit';
import ReadPropertyAgent from './pages/property_agent/view';

import SPCategory from './pages/service_provider/categories/index';
import CreateSPCategory from './pages/service_provider/categories/create';
import EditSPCategory from './pages/service_provider/categories/edit';
import ReadSPCategory from './pages/service_provider/categories/view';

import SPSubCategory from './pages/service_provider/subCategories/index';
import CreateSPSubCategory from './pages/service_provider/subCategories/create';
import EditSPSubCategory from './pages/service_provider/subCategories/edit';
import ReadSPSubCategory from './pages/service_provider/subCategories/view';

import SPServices from './pages/service_provider/services/index';
import CreateSPServices from './pages/service_provider/services/create';
import EditSPServices from './pages/service_provider/services/edit';
import ReadSPServices from './pages/service_provider/services/view';

import EstateActivities from './pages/estate_activities/index';
import CreateActivities from './pages/estate_activities/create';
import EditActivities from './pages/estate_activities/edit';
import ReadActivities from './pages/estate_activities/view';

import './App.css'

const App = () => {

  return (
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route exact path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/user/profile" element={<Profile />} />

        {/* Dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard  />
            </ProtectedRoute>
          }
        />

        {/* Settings */}
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings  />
            </ProtectedRoute>
          }
        />

        {/* Support */}
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <Support  />
            </ProtectedRoute>
          }
        />

        {/* Estates Routes */}
        <Route
          path="/estates"
          element={
            <ProtectedRoute>
              <Estates  />
            </ProtectedRoute>
          }
        />

       <Route
          path="/estate-members"
          element={
            <ProtectedRoute>
              <EstateMembers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-members/:id"
          element={
            <ProtectedRoute>
              <ViewEstateMembers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/create"
          element={
            <ProtectedRoute>
              <CreateEstate  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstate  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/:id"
          element={
            <ProtectedRoute>
              <ReadEstate  />
            </ProtectedRoute>
          }
        />

          {/* Estates Activity Routes */}
          <Route
          path="/estates/activities"
          element={
            <ProtectedRoute>
              <EstateActivities  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/activity/create"
          element={
            <ProtectedRoute>
              <CreateActivities />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/activity/:id/edit"
          element={
            <ProtectedRoute>
              <EditActivities  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/activity/:id"
          element={
            <ProtectedRoute>
              <ReadActivities />
            </ProtectedRoute>
          }
        />
         <Route
          path="/estates/activity/:id/view"
          element={
            <ProtectedRoute>
              <ReadActivities />
            </ProtectedRoute>
          }
        />

        {/* Digital Broker selling Routes */}
        <Route
          path="/property-agency/:pid/land-listed"
          element={
            <ProtectedRoute>
              <PropertyLand  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agency/:pid/land-listed/create"
          element={
            <ProtectedRoute>
              <CreatePropertyLand  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agency/:pid/land-listed/:id/edit"
          element={
            <ProtectedRoute>
              <EditPropertyLand  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agency/land-listed/:id"
          element={
            <ProtectedRoute>
              <ReadPropertyLand  />
            </ProtectedRoute>
          }
        />
          <Route
          path="/property-agency/:pid/houses-listed"
          element={
            <ProtectedRoute>
              <PropertyHouses  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agency/:pid/houses-listed/create"
          element={
            <ProtectedRoute>
              <CreatePropertyHouses  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agency/:pid/houses-listed/:id/edit"
          element={
            <ProtectedRoute>
              <EditPropertyHouses  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agency/:pid/houses-listed/:id"
          element={
            <ProtectedRoute>
              <ReadPropertyHouses  />
            </ProtectedRoute>
          }
        />
         <Route
          path="/property-agency/:pid/staff"
          element={
            <ProtectedRoute>
              <PropertyStaff  />
            </ProtectedRoute>
          }
        />

        {/* Digital Broker rentals Routes */}
        <Route
          path="/estate-agency/:eid/houses-listed"
          element={
            <ProtectedRoute>
              <EstateHouses  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agency/:eid/houses-listed/create"
          element={
            <ProtectedRoute>
              <CreateEstateHouses  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agency/:eid/houses-listed/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstateHouses  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agency/:eid/houses-listed/:id"
          element={
            <ProtectedRoute>
              <ReadEstateHouses  />
            </ProtectedRoute>
          }
        />
         <Route
          path="/estate-agency/:eid/staff"
          element={
            <ProtectedRoute>
              <AgencyStaff  />
            </ProtectedRoute>
          }
        />

         {/* Digital Broker rentals Routes */}
         <Route
          path="/estate-agency/:eid/land-listed"
          element={
            <ProtectedRoute>
              <EstateLand  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agency/:eid/land-listed/create"
          element={
            <ProtectedRoute>
              <CreateEstateLand  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agency/:eid/land-listed/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstateLand  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agency/:eid/land-listed/:id"
          element={
            <ProtectedRoute>
              <ReadEstateLand  />
            </ProtectedRoute>
          }
        />

        {/* Estate Agent Routes */}
        <Route
          path="/estate-agencies"
          element={
            <ProtectedRoute>
              <EstateAgencies  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agencies/create"
          element={
            <ProtectedRoute>
              <CreateEstateAgencies  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agencies/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstateAgencies  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-agencies/:id"
          element={
            <ProtectedRoute>
              <ReadEstateAgencies  />
            </ProtectedRoute>
          }
        />

        {/* Estate Boda Routes */}
        <Route
          path="/estate-boda/:id"
          element={
            <ProtectedRoute>
              <EstateBoda  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-boda/create/:id"
          element={
            <ProtectedRoute>
              <CreateEstateBoda  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-boda/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstateBoda  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-boda/:id/view"
          element={
            <ProtectedRoute>
              <ReadEstateBoda  />
            </ProtectedRoute>
          }
        />

          {/* Estate Boda Stage Routes */}
          <Route
          path="/estate-boda-stage"
          element={
            <ProtectedRoute>
              <EstateBodaStage  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-boda-stage/create"
          element={
            <ProtectedRoute>
              <CreateEstateBodaStage  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-boda-stage/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstateBodaStage  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-boda-stage/:id"
          element={
            <ProtectedRoute>
              <ReadEstateBodaStage  />
            </ProtectedRoute>
          }
        />


        {/* Estate Noticeboard Routes */}
        <Route
          path="/estate-noticeboard"
          element={
            <ProtectedRoute>
              <EstateNoticeboard  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-noticeboard/create"
          element={
            <ProtectedRoute>
              <CreateEstateNoticeboard  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-noticeboard/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstateNoticeboard  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-noticeboard/:id"
          element={
            <ProtectedRoute>
              <ReadEstateNoticeboard  />
            </ProtectedRoute>
          }
        />

        {/* Estate Pay Routes */}
        <Route
          path="/estate-pay"
          element={
            <ProtectedRoute>
              <EstatePay  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-pay/:id/edit"
          element={
            <ProtectedRoute>
              <EditEstatePay  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-pay/:id"
          element={
            <ProtectedRoute>
              <ReadEstatePay  />
            </ProtectedRoute>
          }
        />

        {/* Property Agent Routes */}
        <Route
          path="/property-agencies"
          element={
            <ProtectedRoute>
              <PropertyAgent  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agencies/create"
          element={
            <ProtectedRoute>
              <CreatePropertyAgent  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agencies/:id/edit"
          element={
            <ProtectedRoute>
              <EditPropertyAgent  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-agencies/:id"
          element={
            <ProtectedRoute>
              <ReadPropertyAgent  />
            </ProtectedRoute>
          }
        />

        {/* Service Provider Category Routes */}
        <Route
          path="/service-provider/category"
          element={
            <ProtectedRoute>
              <SPCategory  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/category/create"
          element={
            <ProtectedRoute>
              <CreateSPCategory  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/category/:id/edit"
          element={
            <ProtectedRoute>
              <EditSPCategory  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/category/:id"
          element={
            <ProtectedRoute>
              <ReadSPCategory  />
            </ProtectedRoute>
          }
        />

          {/* Service Provider subcategory Routes */}
          <Route
          path="/service-provider/subcategory/:id"
          element={
            <ProtectedRoute>
              <SPSubCategory  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/subcategory/create/:id"
          element={
            <ProtectedRoute>
              <CreateSPSubCategory  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/subcategory/:id/edit"
          element={
            <ProtectedRoute>
              <EditSPSubCategory  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/subcategory/:id/view"
          element={
            <ProtectedRoute>
              <ReadSPSubCategory  />
            </ProtectedRoute>
          }
        />
        {/* Service Provider Service */}
       <Route
          path="/service-provider/service/:id"
          element={
            <ProtectedRoute>
              <SPServices  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/service/create/:id"
          element={
            <ProtectedRoute>
              <CreateSPServices  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/service/:id/edit"
          element={
            <ProtectedRoute>
              <EditSPServices  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-provider/service/:id/view"
          element={
            <ProtectedRoute>
              <ReadSPServices  />
            </ProtectedRoute>
          }
        />


        {/* Users Routes */}
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/create"
          element={
            <ProtectedRoute>
              <CreateUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/:id/edit"
          element={
            <ProtectedRoute>
              <EditUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/:id"
          element={
            <ProtectedRoute>
              <ReadUsers  />
            </ProtectedRoute>
          }
        />

          {/* estate managers Routes */}
          <Route
          path="/estate/managers"
          element={
            <ProtectedRoute>
              <Users  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate/managers/create"
          element={
            <ProtectedRoute>
              <CreateUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate/managers/:id/edit"
          element={
            <ProtectedRoute>
              <EditUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate/managers/:id"
          element={
            <ProtectedRoute>
              <ReadUsers  />
            </ProtectedRoute>
          }
        />

         {/* property managers Routes */}
         <Route
          path="/property/managers"
          element={
            <ProtectedRoute>
              <Users  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/managers/create"
          element={
            <ProtectedRoute>
              <CreateUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/managers/:id/edit"
          element={
            <ProtectedRoute>
              <EditUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/managers/:id"
          element={
            <ProtectedRoute>
              <ReadUsers  />
            </ProtectedRoute>
          }
        />

         {/* agency managers Routes */}
         <Route
          path="/agency/managers"
          element={
            <ProtectedRoute>
              <Users  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/managers/create"
          element={
            <ProtectedRoute>
              <CreateUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/managers/:id/edit"
          element={
            <ProtectedRoute>
              <EditUsers  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/managers/:id"
          element={
            <ProtectedRoute>
              <ReadUsers  />
            </ProtectedRoute>
          }
        />
     
      </Routes>
    </Router>
  );
};

export default App;