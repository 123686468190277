import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../../layouts/page';
import Spinner from '../../../../components/spinner';
import Submit from '../../../../components/button';
import { config } from '../../../../config';
import Backbtn from '../../../../components/backbtn';
import ImageUploader from '../../../../components/imageuploader';
import Editor from '../../../../components/editor';
import utils from '../../../../helpers/textEdit'

const EditLandListing = () => {
  const token = localStorage.getItem('auth_token');
  const { id } = useParams(); 
  const { pid } = useParams(); 
  const [loading, setLoading] = useState(false);
  const [estates, setEstates] = useState([]);
  const [submitting, setSubmitting] = useState(false); // Prevent double submission
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    category: '',
    agency_id:pid,
    estate_name: '',
    address: '',
    plot_number: '',
    block_number: '',
    location: '',
    volume_number: '',
    folio_number: '',
    land_size: '',
    projected_cost: '',
    description:'',
    status:'',
    owner_name: '',
    owner_nin_number: '',
    owner_residence: '',
    image:'',
    owner_passport_photo:''
  });

  useEffect(() => {
    document.title = "Tuwe | Edit Land Listing";
    fetchEstates();
    fetchLandListing(); // Fetch the existing land listing details
  }, []);

  // Fetch estates
  const fetchEstates = async () => {
    try {
      const response = await axios.get(config.api_path + '/estates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setEstates(response.data.data);
      } else {
        toast.error('No estates found.');
      }
    } catch (error) {
      toast.error('Failed to fetch estates.');
    }
  };
  
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = utils.formatPrice(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue
    }));
  };
  // Fetch the existing land listing by ID
  const fetchLandListing = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/land-listings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        const landListing = response.data.data;
        console.log("listing",landListing)
        // Populate form with existing data
        setFormData({
          category: landListing.category || 'Mailo',
          agency_id:pid,
          estate_name: landListing.estate_name || '',
          address: landListing.address || '',
          plot_number: landListing.plot_number || '',
          block_number: landListing.block_number || '',
          location: landListing.location || '',
          volume_number: landListing.volume_number || '',
          folio_number: landListing.folio_number || '',
          land_size: landListing.land_size || '',
          projected_cost: landListing.projected_cost || '',
          status: landListing.status || '',
          description: landListing.description || '',
          owner_name: landListing.owner.name || '',
          owner_nin_number: landListing.owner.nin_number || '',
          owner_residence: landListing.owner.residence || '',
          image: landListing.image || null,
          owner_passport_photo: landListing.owner.passport_photo || null
        });
      } else {
        toast.error('Land listing not found.');
      }
    } catch (error) {
      toast.error('Failed to fetch land listing.');
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent submission while in process
    if (submitting) return;

    setSubmitting(true);
    setLoading(true);
    const payload = new FormData();
    for (const key in formData) {
      if (key ==='image' || key ==='owner_passport_photo'){
          if((typeof formData[key]) != 'string' ){
            payload.append(key, formData[key])
          }
      }else{
        payload.append(key, formData[key])
      }
    }

    try {
      await axios.post(`${config.api_path}/land-listings/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setLoading(false);
      toast.success('Land listing updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

      setSubmitting(false);

    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Update Land Listing</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              {/* Owner Details */}
              <fieldset>
                <legend>Owner's Information</legend>
                <section className='row'>
                  <div className='col-md-8'>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Full Name<span>*</span></label>
                        <input
                          type="text"
                          name="owner_name"
                          className="form-control"
                          value={formData.owner_name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>NIN Number<span>*</span></label>
                        <input
                          type="text"
                          name="owner_nin_number"
                          className="form-control"
                          value={formData.owner_nin_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-md-6">
                        <label>Current Residence<span>*</span></label>
                        <input
                          type="text"
                          name="owner_residence"
                          className="form-control"
                          value={formData.owner_residence}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Address<span>*</span></label>
                        <input
                          type="text"
                          name="address"
                          className="form-control"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Estate<span>*</span></label>
                        <select
                          className="form-control"
                          name="estate_name"
                          value={formData.estate_name}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select Estate</option>
                          {estates.length > 0 ? (
                            estates.map((estate) => (
                              <option key={estate.name} value={estate.name}>
                                {estate.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No estates available</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <ImageUploader
                    title="Passport Photo"
                    image={formData.owner_passport_photo}
                    onChange={(e) => setFormData((prev) => ({ ...prev, owner_passport_photo: e.target.files[0] }))}
                    required
                  />
                </section>
              </fieldset>

              <fieldset>
                <legend>Property Information</legend>
                <section className='row'>
                  <div className='col-md-8'>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label className="col-form-label">Category<span>*</span></label>
                        <select
                          className="form-control"
                          name="category"
                          value={formData.category}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select Category</option>
                          <option value="Mailo">Mailo land</option>
                          <option value="Kabaka">Kabaka land</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Land Size<span>*</span></label>
                        <input
                          type="text"
                          name="land_size"
                          className="form-control"
                          value={formData.land_size}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Plot Number<span>*</span></label>
                        <input
                          type="text"
                          name="plot_number"
                          className="form-control"
                          value={formData.plot_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Block Number<span>*</span></label>
                        <input
                          type="text"
                          name="block_number"
                          className="form-control"
                          value={formData.block_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Volume Number<span>*</span></label>
                        <input
                          type="text"
                          name="volume_number"
                          className="form-control"
                          value={formData.volume_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Folio Number<span>*</span></label>
                        <input
                          type="text"
                          name="folio_number"
                          className="form-control"
                          value={formData.folio_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Location<span>*</span></label>
                        <input
                          type="text"
                          name="location"
                          className="form-control"
                          value={formData.location}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Projected Cost<span>*</span></label>
                        <div class="input-wrapper">
                        <input
                          type="text"
                          name="projected_cost"
                          className="form-control price-input"
                          value={formData.projected_cost}
                          onChange={handlePriceChange}
                          placeholder="0.00"
                          required
                        />
                        </div>
                       
                      </div>
                    </div>
                    <Editor
                      label="Description"
                      width="col-md-12"
                      onChange={handleEditorChange}
                      title="description"
                      value={formData.description}
                    />
                  </div>
                  <div className='col-md-4'>
                       <div className="form-group col-md-12">
                          <label>Status</label>
                          <select
                            name="status"
                            className="form-control"
                            value={formData.status}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Denied">Denied</option>

                          </select>
                        </div>
                        <ImageUploader
                          width="col-md-12"
                          title="Land Image"
                          image={formData.image}
                          onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))}
                          required
                        />
                  </div>
                 
                </section>
              </fieldset>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <Submit text="Create Land Listing" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Spinner />}
    </Layout>
  );
};

export default EditLandListing;
