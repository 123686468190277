import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import Spinner from '../../components/spinner';
import { config } from '../../config';
import Submit from '../../components/button';
import Backbtn from '../../components/backbtn';
import Editor from '../../components/editor/index';
import ImageUploader from '../../components/imageuploader';

const Create = () => {
  const estateID = localStorage.getItem('estateID');
  const navigate = useNavigate();

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    image: '',
    estate_id: estateID,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'Tuwe | Create Activity';
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const formDataToSubmit = new FormData(); // Using FormData for file uploads
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSubmit.append(key, value);
    });

    try {
      await axios.post(`${config.api_path}/estate-activities`, formDataToSubmit, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success('Estate activity created successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

      setFormData({
        name: '',
        description: '',
        start_date: '',
        end_date: '',
        image: '',
        estate_id: estateID,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      console.log(error);
      console.error('Submission error:', error);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create Activity</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              {/* First Column */}
              <div className="row">
                {/* Title */}
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    Activity Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    Start Date<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    className="form-control"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    End Date<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <Editor
                  label="Description"
                  value={formData.description}
                  onChange={handleEditorChange}
                />
                <ImageUploader
                        title={`Activity Image`}
                        image={formData.image}
                        onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly

                      />
              </div>

              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
