import React from 'react';
import { Navigate } from 'react-router-dom';


const ProtectedRoute =({ children })=>{
  const token = localStorage.getItem('auth_token');  // Get the token from localStorage
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/" replace />;
  }
  // If authenticated, allow access to the protected component
  return children;
}

const handleLogout=()=>{
  localStorage.removeItem('auth_token');
  <Navigate to="/" replace />;
}

export default ProtectedRoute;