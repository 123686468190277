import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = ({ label, value, onChange, width="col-md-8" }) => (
  <div className={"form-group "+width} style={{marginBottom:"10px",padding:'0'}}>
    <label>{label}</label>
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={onChange}
      className="ckeditor"
    />
  </div>
  
);

export default Editor;
