import {React, useState}  from 'react'
import {Link} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../assets/tuwe.png'
import { config } from '../config';
import axios from 'axios'

const Nav = ({handleSideMenu}) => {

  const username = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  const token = localStorage.getItem('auth_token');  
  const userimg = localStorage.getItem('image');
  const [showNav, setShowNav] = useState(false);

  const handleLogout =()=>{
   
    axios
    .post(config.api_path+'/logout', {
      headers: {
        Authorization: `Bearer ${token}`, // Include auth token in headers
      },
    })
    .then((response) => {
      console.log(response.data)
      localStorage.clear();
      window.location='/'
    })
    .catch((error) => {
      localStorage.clear();
      window.location='/';  // Redirect to login
      console.log(error)
    });
  }

  return (
    <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
      <a class="navbar-brand brand-logo" href="#">
          <img src={Logo} alt="logo" width="70%" /> 
      </a>
      <a class="navbar-brand brand-logo-mini" href="#">
          <img src={Logo} alt="logo" width="70%" /> 
      </a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center">
      <ul class="navbar-nav">
        <li class="nav-item font-weight-semibold d-none d-lg-block">Help : +256 702888849</li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
          <Link class="nav-link dropdown-toggle" 
              id="UserDropdown" 
              to="#" 
              data-toggle="dropdown" 
              aria-expanded="false">
            <img class="img-xs rounded-circle" src={userimg? userimg: "/assets/images/faces/face8.jpg"} alt="Profile image"/> 
          </Link>
          <div class={`dropdown-menu dropdown-menu-right navbar-dropdown`} aria-labelledby="UserDropdown">
            <div class="dropdown-header text-center">
              <img class="img-md rounded-circle" src={userimg? userimg: "/assets/images/faces/face8.jpg"} alt="Profile image"/>
              <p class="mb-1 mt-3 font-weight-semibold">{username}</p>
              <p class="font-weight-light text-muted mb-0">{email}</p>
            </div>
            <Link class="dropdown-item" to={`/user/profile`}>My Profile <span class="badge badge-pill badge-danger">1</span>
            <i class="dropdown-item-icon ti-dashboard"></i></Link>
            <Link class="dropdown-item" to='#' onClick={handleLogout} >Sign Out<i class="dropdown-item-icon ti-power-off"></i></Link>
          </div>
        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
       type="button" data-toggle="off-canvas" onClick={handleSideMenu}>
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>
  )
}

export default Nav