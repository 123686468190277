import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Pagination from '../../components/pagination';
import Dropdown from './dropdown';
import Backbtn from '../../components/backbtn';
import utils from '../../helpers/textEdit'

const Index = () => {
  const estateID = localStorage.getItem('estateID');
  const estateName = localStorage.getItem('estateName');
  const [estateActivities, setEstateActivities] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem('auth_token');
  const itemsPerPage = 15;

  const fetchEstateActivities = (page) => {
    axios
      .get(`${config.api_path}/estates/${estateID}/estate-activities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { page, per_page: itemsPerPage },
      })
      .then((response) => {
        console.log("activities",response)
        setEstateActivities(response.data.activities || []); // Default to an empty array if undefined
        setTotal(response.data.activities.length || 0); // Default to 0 if undefined
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Failed to fetch estate activities. Please try again.');
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = "Tuwe | Estate Activities";
    setLoading(true);
    fetchEstateActivities(currentPage);
  }, [currentPage]);

  const totalPages = Math.ceil(total / itemsPerPage);

  const escapeHtmlTags = (text) => {
    return text.replace(/<\/?p>/g, '');
  };

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>{estateName} Activities</h3>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to="/estates/activity/create">
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Activity
              </button>
            </Link>
            {loading ? (
              <Spinner />
            ) : (
              <>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {estateActivities.length > 0 ? (
                      estateActivities.map((activity, index) => (
                        <tr key={activity.id}>
                          <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                          <td>
                            <Link to={`/estates/activity/${activity.id}/view`}>
                                {utils.capitalizeWords(activity.name)}
                            </Link>
                          </td>
                          <td>{escapeHtmlTags(utils.shorten_string(activity.description,30))}</td>
                          <td>{new Date(activity.start_date).toLocaleDateString()}</td>
                          <td>{new Date(activity.end_date).toLocaleDateString()}</td>
                          <td>{ utils.statusDisplay(activity.status) }</td>
                          <td>
                            <Dropdown stageId={activity.id} onDelete={() => fetchEstateActivities(currentPage)} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No estates activity found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
                <Pagination 
                  currentPage={currentPage} 
                  totalPages={totalPages} 
                  setCurrentPage={setCurrentPage} 
                />
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
