import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../components/pagination'
import Service from './services'
import utils from '../../helpers/textEdit';
import useFetch from '../../helpers/fetch';

const Index = () => {
  const [agency, setAgency] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const dropdownRef = useRef(null);
  const itemsPerPage = 10; // Define how many items to display per page

  useEffect(() => {
    document.title = "Tuwe | Property Agents";
    fetchAgency(currentPage);
  }, [currentPage]);
//
  const fetchAgency = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/property-agencies?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data)
      setAgency(response.data.data);
      setTotal(response.data.meta.total);
      setLoading(false);
    } catch (error) {
      console.error(error); 
      toast.error('Failed to fetch agency. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // Calculate the total number of pages
  const totalPages = Math.ceil(total / itemsPerPage);
  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Property Agencies</h3>
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to="/property-agencies/create">
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Agency
              </button>
            </Link>

            {loading ? (
              <Spinner />
            ) : (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="5%">ID</th>
                    <th></th>
                    <th>Agency Name</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Created On</th>
                    <th>Actions</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {agency.map(boda => (
                    <tr key={boda.id}>
                      <td>{boda.id}</td>
                      <th><img class="img-xs rounded-circle" src={boda.logo} alt="agency"/></th>
                      <td><Link to={`/property-agencies/${boda.id}`}>{boda.name}</Link></td>
                      <td>{boda.phone}</td>
                      <td>{ utils.statusDisplay(boda.status) }</td>
                      <td>{utils.dateToNormal(boda.created_at)}</td>
                      <td>
                        <Dropdown serviceId={boda.id} onDelete={() => fetchAgency(currentPage)} />
                      </td>
                      <td>
                        <Service estates={boda} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
