import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // If using React Router v6, use useNavigate

const useFetch = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('auth_token');
  const navigate = useNavigate(); // React Router v6 hook for navigation

  // Define fetchData as a callback so it can be called on demand
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(url, {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
        ...options,
      });
      setData(response.data);
    } catch (err) {
      // If token expired (401 error), redirect to login page
      if (err.response?.status === 401) {
        localStorage.removeItem('auth_token'); // Clear the expired token
        window.location ='/'; // Redirect to login page
      } else {
        const errorMessage = err.response?.data?.message || 'Failed to fetch data. Please try again.';
        setError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  }, [url, token, options, navigate]); // Make sure to include `navigate` in the dependency array

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refresh: fetchData };
};

export default useFetch;
