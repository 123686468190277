import {React, useState} from 'react';
import "./model.css"

const Modal = ({ showModal, toggleModal, handleDelete, title }) => {

  const [isDeleting, setIsDeleting] = useState(false);

  return (
    showModal && (
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button type="button" className="close" onClick={toggleModal} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <h5>Are you sure you want to delete this {title}?</h5>
              <p>This action cannot be undone.</p>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <button className="btn btn-secondary" onClick={toggleModal}>
                  Cancel
                </button>
                <button
                  
                  className="btn btn-danger ml-3"
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  {isDeleting ? 'Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
