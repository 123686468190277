import React from 'react'

const Footer = () => {
  return (
    <footer class="footer">
    <div class="container-fluid clearfix">
      <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © tuwe.com 2024</span>
    </div>
  </footer>
  )
}

export default Footer