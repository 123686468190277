import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import { config } from '../../config';
import Submit from '../../components/button';
import { useNavigate } from 'react-router-dom';
import Backbtn from '../../components/backbtn';
import Editor from '../../components/editor';
import ImageUploader from '../../components/imageuploader';

const Create = () => {
  const navigate = useNavigate();
  const estateID = localStorage.getItem('estateID');

  const [formData, setFormData] = useState({
    title: '',
    sub_title: '',
    brief: '',
    message_body: '',
    image: null,
    estate_id: estateID,
  });
  
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    document.title = 'Tuwe | Create Message';
  }, []);

  const handleEditorBriefChange = (event, editor) => {
    const data = editor.getData();
    setFormData(prevData => ({ ...prevData, brief: data }));
  };

  const handleEditorMessageChange = (event, editor) => {
    const data = editor.getData();
    setFormData(prevData => ({ ...prevData, message_body: data }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    setLoading(true);
    const token = localStorage.getItem('auth_token');
    const payload = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      payload.append(key, value)
    });

    try {
      await axios.post(`${config.api_path}/estate-noticeboards`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Estate message created successfully!');
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create NoticeBoard Message</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-4">
                  <label className="col-form-label">Title<span>*</span></label>
                  <input type="text" name="title" className="form-control" value={formData.title} onChange={handleInputChange} required />
                </div>
                <div className="form-group col-md-8">
                  <label className="col-form-label">Sub Title<span>*</span></label>
                  <input type="text" name="sub_title" className="form-control" value={formData.sub_title} onChange={handleInputChange} required />
                </div>
                <Editor label="Brief" value={formData.brief} onChange={handleEditorBriefChange} />
                <ImageUploader title="NoticeBoard Image" image={formData.image} onChange={(e) => setFormData(prev => ({ ...prev, image: e.target.files[0] }))} required />
              </div>
              <div className="row">
                <Editor label="Message" width="col-md-8" value={formData.message_body} onChange={handleEditorMessageChange} />
              </div>
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
