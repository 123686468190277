import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Backbtn from '../../components/backbtn';

const View = () => {
  const [activity, setActivity] = useState({ name: '', description: '', image: '', start_date: '', end_date: '' });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Tuwe | Details';

    const fetchData = async () => {
      if (!storedToken) {
        toast.error('Authentication token is missing. Please log in again.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${config.api_path}/estate-activities/${id}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        // Check if response is valid
        if (response.data.data) {
          setActivity(response.data.data); // Adjust based on your API response structure
        } else {
          toast.error('No data found for this activity.');
        }
      } catch (error) {
        toast.error('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, storedToken]);

  return (
    <Layout>
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <h3 style={{textAlign:'center'}}>{activity.name}</h3>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <div className="row">
                
                <div className="image-section col-mb-6" style={{padding:'20px'}}><Backbtn />
                  <img 
                    src={activity.image || '/images/placeholder.png'} // Placeholder image
                    alt={activity.name || 'Event Image'} 
                    className="img-fluid" // Make image responsive
                  />
                </div>
              <div className="col-md-6" style={{marginTop:"50px", backgroundColor:"#F3F3F3", padding:'20px'}}>
                {/* Description Section */}
                {activity.description && (
                  <div className="description-section mb-3">
                    <h3>Description</h3>
                    <div dangerouslySetInnerHTML={{ __html:activity.description }} />
                  </div>
                )}

                {/* Date Section */}
                <div className="date-section" style={{display:'flex', justifyContent:'space-between'}}>
                  <p style={{marginRight:'10px'}}><strong>Start Date:</strong> {activity.start_date || 'Not specified'}</p>
                  <p><strong>End Date:</strong> {activity.end_date || 'Not specified'}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default View;
