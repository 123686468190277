import React from 'react'
import Logo from '../assets/tuwe.png'

const Auth = ({children}) => {
  return (
    <section class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth theme-one">
        <div class="row w-100">
          <div class="col-lg-4 mx-auto " style={{display: 'flex',justifyContent: 'center',flexDirection:'column'}} >
          <img style={{marginLeft:'25%'}} src={Logo} alt='' width='40%' />
            <div class="auto-form-wrapper">
               {children}
            </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Auth