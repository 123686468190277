import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


// Custom Dropdown Component
const Index = ({ estates, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  localStorage.setItem('estateID', estates.id);
  localStorage.setItem('estateName', estates.name);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`#dropdown-${estates.id}`)) {
        setIsOpen(false);
      }
    };

    // Add event listener to close dropdown on outside click
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [estates.id]);


  return (
    <div className="dropdown" id={`dropdown-${estates.id}`} style={{ position: 'relative' }}>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        Dashboard
      </button>
      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', position: 'absolute', top: '100%', zIndex: 1 }}>
    
      <Link to={`/estate-agency/${estates.id}/land-listed`}>
            <button className="dropdown-item" >
            Land
            </button>
        </Link>
        <Link to={`/estate-agency/${estates.id}/houses-listed`}>
            <button className="dropdown-item" >
            Houses
            </button>
        </Link>
        <Link to={`/estate-agency/${estates.id}/staff`}>
            <button className="dropdown-item" >
            Staff
            </button>
        </Link>
      
      </div>
    </div>
  );
};

export default Index;
