import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import Spinner from '../../components/spinner'
import { config } from '../../config';
import Backbtn from '../../components/backbtn';


const NoticeboardView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticeboard, setNoticeboard] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch noticeboard details
  const fetchNoticeboardDetails = async () => {
    const token = localStorage.getItem('auth_token');
    try {
      const response = await axios.get(`${config.api_path}/estate-noticeboards/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNoticeboard(response.data.data);
    } catch (error) {
      console.error('Error fetching noticeboard details:', error);
      toast.error('Failed to load noticeboard details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'Tuwe | View Noticeboard';
    fetchNoticeboardDetails();
  }, [id]);

  // Placeholder image URL

  return (
    <Layout>
      <div className="col-12 grid-margin">
        {(loading? <Spinner />:
        <div className="card noticeboard-card">
          <h3 style={{textAlign:"center"}}> NoticeBoard</h3>
          <Backbtn />
          <div className="card-body noticeboard-content"> 
            <div className="noticeboard-image">
              <img
                src={noticeboard.image}
                alt="Estate"
                className="img-fluid"
              />
            </div>
            <div className="noticeboard-details">
              <h4 className="noticeboard-title">{noticeboard.title}</h4>
              <h6 className="noticeboard-subtitle">{noticeboard.sub_title}</h6>
              <div className="noticeboard-brief">
                <strong>Brief:</strong>
                <div dangerouslySetInnerHTML={{ __html: noticeboard.brief }} />
              </div>
              <div className="noticeboard-message">
                <strong>Message:</strong>
                <div dangerouslySetInnerHTML={{ __html: noticeboard.message_body }} />
              </div>
             
            </div>
          </div>
        </div>
        )}
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default NoticeboardView;
