import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../../../config';
import Spinner from '../../../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../../../components/pagination'
import Backbtn from '../../../../components/backbtn'
import utils from '../../../../helpers/textEdit'

const Index = () => {
  const {eid} = useParams()
  const [houseListings, setHouseListings] = useState([]);
  const [Agency, setAgency] = useState({});

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const dropdownRef = useRef(null);
  const itemsPerPage = 15; // Define how many items to display per page

  useEffect(() => {
    document.title = "Tuwe | House Listings";
    fetchHouseListings(currentPage);
    fetchAgency();
  }, [currentPage]);
  const fetchAgency = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/agencies/${eid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data)
      setAgency(response.data.data);
    } catch (error) {
      console.error(error); 
      toast.error('Failed to fetch Agency. Please try again.');
      setLoading(false);
    }
  };
  const fetchHouseListings = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/house-listings?agency_id=${eid}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data)
      setHouseListings(response.data.data);
      setTotal(1);
      setLoading(false);
    } catch (error) {
      console.error(error); // Log error for debugging
      toast.error('Failed to fetch house listings. Please try again.');
      setLoading(false);
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>{Agency.name} House Listings</h3>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to={'/estate-agency/'+eid+'/houses-listed/create'}>
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New House
              </button>
            </Link>

            {loading ? (
              <Spinner />
            ) : (
              <div className="table-responsive">
               <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Owners Name</th>
                    <th>Listing Type</th>
                    <th>Address</th>
                    <th>Bedrooms</th>
                    <th>Price(Ugx)</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {houseListings.map(boda => (
                    <tr key={boda.id}>
                      <td>{boda.id}</td>
                      <td><img class="img-xs rounded-circle" src={boda.image} alt="estate"/></td>
                      <td><Link to={`/property-agency/${eid}/houses-listed/${boda.id}`}>{boda.owner.name}</Link></td>
                      <td>{boda.listing_type}</td>
                      <td>{boda.address}</td>
                      <td>{boda.bedrooms}</td>
                      <td><span className='price'>{utils.formatPrice(boda.price)}</span></td>
                      <td>{utils.statusDisplay(boda.status)}</td>
                      <td>
                        <Dropdown estateId={boda.id} eid={eid} onDelete={() => fetchHouseListings(currentPage)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}

                <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
