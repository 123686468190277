import { React, useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import AuthLayout from '../../layouts/auth'
import { config } from '../../config/'
import isTokenValid from '../../helpers/tokenExpired'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password")

  const navigate = useNavigate(); // To redirect on successful login
  if(isTokenValid()){
      navigate('/dashboard')
  }

  const handleType =()=>{
      if (type =="password"){
          setType("text")
      }else{
          setType("password")
      }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(''); // Clear any previous errors
    try {
      const response = await axios.post(config.api_path + "/login", {
        email,
        password,
      });

      let { token, user, estate, agency } = response.data;
      localStorage.setItem('auth_token', token);
      localStorage.setItem('userID', user.id);
      localStorage.setItem('username', user.name);
      localStorage.setItem('email', user.email);
      localStorage.setItem('image', user.image);
      localStorage.setItem('role', user.role);
      if(user.role =='Estate Manager'){
           localStorage.setItem('estateID', estate.id);
           localStorage.setItem('estateName', estate.name);
      }else if(user.role =='Property Agent'){
        localStorage.setItem('agencyID', agency.id);
        localStorage.setItem('agencyName', agency.name);
      }

      // Redirect to the dashboard or any protected route
      window.location='/dashboard';
      toast.success(response.data);
    } catch (err) {
      if (err.response){
          setError('Login Failed: '+err.response.data.error);
          toast.error('Login Failed: '+err.response.data.error);

      }else{
        toast.error('Error: Unable to connect!');
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    document.title = "Tuwe | Login"
  }, []);

  return (
    <AuthLayout>
      <h3 style={{ textAlign: "center", margin: "20px 0" }}>Dashboard</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="label">Email Address</label>
          <div className="input-group">
          <div className="input-group-append">
              <span className="input-group-text">
              <i className="menu-icon fas fa-user-tie" style={{color:"#501062"}}></i>
              </span>
            </div>
            <input type="text" 
                className="form-control" 
                name="email" 
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label">Password</label>
          <div className="input-group">
          <div className="input-group-append">
              <span className="input-group-text">
              <i class="fa-solid fa-eye"
                 onClick={handleType}
                style={{color:"#501062", fontSize:"12px",cursor:"pointer"}}></i>
              </span>
            </div>
            <input type={type} 
                   name="password" 
                   className="form-control" 
                   placeholder="*********"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
                   required
             />
           
          </div>
        </div>
        <div className="form-group">
          <button className="btn btn-primary submit-btn btn-block" 
                  disabled={loading} 
                  type='submit'
          >{loading ? 'Logging in...' : 'Login'}</button>
        </div>
        <div className="form-group d-flex justify-content-between">
          <Link to="/forgot-password" className="text-small forgot-password text-black">Forgot Password</Link>
        </div>
      </form>
      <ToastContainer />
    </AuthLayout>
  )
}

export default Login;
