import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Sidebar = ({sideMenu}) => {
    const username = localStorage.getItem('username');
    const userrole = localStorage.getItem('role');
    const agencyName = localStorage.getItem('agencyName');
    const agencyID = localStorage.getItem('agencyID');

    const location = useLocation(); // Hook to get current URL path

    // State for toggling dropdown menus
    const [isBrokerOpen, setBrokerOpen] = useState(false);
    const [isUsersOpen, setUsersOpen] = useState(false);

    // Refs for submenu containers
    const brokerMenuRef = useRef(null);
    const usersMenuRef = useRef(null);

    // Function to determine if the current link is active
    const isActive = (path) => location.pathname === path;

    // Check if any submenu item under Digital Broker is active
    const isBrokerSubmenuActive = ['/digital-broker/property-agency/'+agencyID+'/land', '/digital-broker/property-agency/'+agencyID+'/houses'].includes(location.pathname);

    // Check if any submenu item under Users Management is active
    const isUsersSubmenuActive = ['/users', '/users/audit-trail'].includes(location.pathname);

    // Automatically open dropdown if a submenu item is active
    useEffect(() => {
        if (isBrokerSubmenuActive) setBrokerOpen(true);
        if (isUsersSubmenuActive) setUsersOpen(true);
    }, [isBrokerSubmenuActive, isUsersSubmenuActive]);

    // Toggle dropdown functions with smooth height transition
    const toggleBrokerDropdown = () => {
        setBrokerOpen(!isBrokerOpen);
        if (brokerMenuRef.current) {
            brokerMenuRef.current.style.height = isBrokerOpen ? '0px' : `${brokerMenuRef.current.scrollHeight}px`;
        }
    };

    const toggleUsersDropdown = () => {
        setUsersOpen(!isUsersOpen);
        if (usersMenuRef.current) {
            usersMenuRef.current.style.height = isUsersOpen ? '0px' : `${usersMenuRef.current.scrollHeight}px`;
        }
    };

    // Update height when state or content changes
    useEffect(() => {
        if (brokerMenuRef.current) {
            brokerMenuRef.current.style.height = isBrokerOpen ? `${brokerMenuRef.current.scrollHeight}px` : '0px';
        }
        if (usersMenuRef.current) {
            usersMenuRef.current.style.height = isUsersOpen ? `${usersMenuRef.current.scrollHeight}px` : '0px';
        }
    }, [isBrokerOpen, isUsersOpen]);

    return (
        <nav className={(sideMenu)? "sidebar sidebar-offcanvas":"sidebar"}  id="sidebar">
            <ul className="nav">
            <li className="nav-item nav-profile"><h3 style={{color:"#fff"}}>{ agencyName.toLocaleUpperCase() }</h3>
            <a href="#" className="nav-link">
                        <div className="profile-image">
                            <img className="img-xs rounded-circle" src="../../assets/images/faces/face8.jpg" alt="profile" />
                            <div className="dot-indicator bg-success"></div>
                        </div>
                        <div className="text-wrapper">
                            <p className="profile-name">{username}.</p>
                            <span>{userrole}</span>
                        </div>
                    </a>
                </li>
                <li className="nav-item nav-category">Main Menu</li>

                {/* Dashboard */}
                <li className={`nav-item ${isActive('/dashboard') ? 'active' : ''}`}>
                    <Link className="nav-link" to="/dashboard">
                        <i className="menu-icon fas fa-tachometer-alt"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Dashboard</span>
                    </Link>
                </li>

                {/* Digital Broker dropdown */}
                <li className="nav-item">
                    <a className="nav-link" onClick={toggleBrokerDropdown} aria-expanded={isBrokerOpen || isBrokerSubmenuActive}>
                        <i className="menu-icon fas fa-briefcase"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Digital Broker</span>
                        <i className={`menu-arrow ${isBrokerOpen || isBrokerSubmenuActive ? 'open' : ''}`}></i>
                    </a>
                    <div
                        className={`collapse ${isBrokerOpen || isBrokerSubmenuActive ? 'show' : ''}`}
                        id="digital-broker"
                        ref={brokerMenuRef}
                    >
                        <ul className="nav flex-column sub-menu">
                            <li className={`nav-item ${isActive('/digital-broker/property-agency/'+agencyID+'/land-listed') ? 'active' : ''}`}>
                                <Link className="nav-link" to={'/digital-broker/property-agency/'+agencyID+'/land-listed'}>
                                    <i className="menu-icon fas fa-map"></i> {/* Font Awesome icon */}
                                    <span className="menu-title">Land</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/digital-broker/property-agency/'+agencyID+'/houses-listed') ? 'active' : ''}`}>
                                <Link className="nav-link" to={'/digital-broker/property-agency/'+agencyID+'/houses-listed'}>
                                    <i className="menu-icon fas fa-home"></i> {/* Font Awesome icon */}
                                    <span className="menu-title">Houses</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                {/* Users Management dropdown */}
                <li className="nav-item">
                    <a className="nav-link" onClick={toggleUsersDropdown} aria-expanded={isUsersOpen || isUsersSubmenuActive}>
                        <i className="menu-icon fas fa-users-cog"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Users Management</span>
                        <i className={`menu-arrow ${isUsersOpen || isUsersSubmenuActive ? 'open' : ''}`}></i>
                    </a>
                    <div
                        className={`collapse ${isUsersOpen || isUsersSubmenuActive ? 'show' : ''}`}
                        id="users"
                        ref={usersMenuRef}
                    >
                        <ul className="nav flex-column sub-menu">
                            <li className={`nav-item ${isActive('/users') ? 'active' : ''}`}>
                                <Link className="nav-link" to="/users">
                                    <i className="menu-icon fas fa-user"></i> {/* Font Awesome icon */}
                                    <span className="menu-title">Users</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/users/audit-trail') ? 'active' : ''}`}>
                                <Link className="nav-link" to="/users/audit-trail">
                                    <i className="menu-icon fas fa-file-alt"></i> {/* Font Awesome icon */}
                                    <span className="menu-title">Audit Trail</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                {/* Settings */}
                <li className={`nav-item ${isActive('/settings') ? 'active' : ''}`}>
                    <Link className="nav-link" to="/settings">
                        <i className="menu-icon fas fa-cog"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Settings</span>
                    </Link>
                </li>

                {/* Support */}
                <li className={`nav-item ${isActive('/support') ? 'active' : ''}`}>
                    <Link className="nav-link" to="/support">
                        <i className="menu-icon fas fa-life-ring"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Support</span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Sidebar;
