import React from 'react'
import FormField from '../formfield';
import Editor from '../editor';
import ImageUploader from '../imageuploader';

const Index = ({formData, setFormData, handleArticleChange, removeArticle}) => {

  // Updated handleChange function to properly handle file input
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    const updatedArticles = [...formData.articles];
    updatedArticles[index].imageUrl = URL.createObjectURL(file);  // Store image URL for preview
    updatedArticles[index].image = file;  // Store image URL for preview
    setFormData((prevData) => ({ ...prevData, articles: updatedArticles }));
  }

  return (
    <div className="form-row" style={{ display: 'flex', flexDirection: 'column' }}>
      {formData.articles.map((article, index) => (
        <div key={index} style={{ margin: '20px 0'}}>
          <FormField 
            label="Article Title"
            width="col-md-8"
            name={`title-${index}`}
            value={article.title}
            onChange={(e) => {
              const updatedArticles = [...formData.articles];
              updatedArticles[index].title = e.target.value;
              setFormData((prevData) => ({ ...prevData, articles: updatedArticles }));
            }}
            required
          />
          <div className='form-row'>
            <Editor
              label={`Article ${index + 1}`}
              value={article.content}
              onChange={(event, editor) => handleArticleChange(index, event, editor)}
            />                    
            <ImageUploader
              title={`Article ${index + 1} image`}
              image={article.image}
              onChange={(e) => handleImageChange(e, index)} // Pass index to handleChange
            />

            <button 
              type="button" 
              className="btn btn-outline-primary ml-auto"  // Bootstrap utility for aligning the button
              onClick={() => removeArticle(index)}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Index;
