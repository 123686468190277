import React, { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import Spinner from '../../components/spinner';
import { config } from '../../config';
import Submit from '../../components/button';
import Backbtn from '../../components/backbtn';
import Editor from '../../components/editor/index';
import ImageUploader from '../../components/imageuploader';

const Edit = () => { 
  const { id } = useParams();
  const estateID = localStorage.getItem('estateID');
  const token = localStorage.getItem('auth_token');
  const navigate = useNavigate();

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    image: '',
    estate_id: estateID,
    status:''
  });
  const [loading, setLoading] = useState(false);

  // Function to fetch estates
  const fetchActivity = () => {
    setLoading(true);

    axios.get(`${config.api_path}/estate-activities/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const data = response.data.data;
      setFormData({
        name: data.name,
        description: data.description,
        start_date: data.start_date,
        end_date: data.end_date,
        image: data.image,
        estate_id: estateID,
        status:data.status
      });
      setLoading(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = 'Tuwe | Update Activity';
    fetchActivity();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = new FormData(); // Using FormData for file uploads
    Object.entries(formData).forEach(([key, value]) => {
      if (key ==='image'){
          if((typeof formData[key]) != 'string' ){
            payload.append(key, formData[key])
          }
      }else{
        payload.append(key, formData[key])
      }
    });

    try {
      await axios.post(`${config.api_path}/estate-activities/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success('Estate activity updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

    } catch (error) {
      setLoading(false);
      toast.error('Failed to update activity. Please try again.');
      console.error('Submission error:', error);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Update Activity</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* Name */}
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    Activity Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {/* Start Date */}
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    Start Date<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    className="form-control"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {/* End Date */}
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    End Date<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <Editor
                  label="Description"
                  value={formData.description}
                  onChange={handleEditorChange}
                />
                <div className='col-md-4'>
                       <div className="form-group col-md-12">
                          <label>Status</label>
                          <select
                            name="status"
                            className="form-control"
                            value={formData.status}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Denied">Denied</option>

                          </select>
                        </div>
                        <ImageUploader
                            width="col-md-12"
                            title={`Activity Image`}
                            image={formData.image}
                            onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly
                          />
                </div>
                
              </div>
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;
