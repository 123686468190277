import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../../config';
import Spinner from '../../../components/spinner';
import Dropdown from './dropdown'
import Pagination from '../../../components/pagination'
import Backbtn from '../../../components/backbtn';
import utils from '../../../helpers/textEdit'

const Index = () => {
  const { id } = useParams()
  const estateID = localStorage.getItem('estateID');
  const estateName = localStorage.getItem('estateName');
  const [serviceProvider, setServiceProvider] = useState({});
  const [loading, setLoading] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  const token = localStorage.getItem('auth_token');
  const dropdownRef = useRef(null);

  // Fetch service provider data based on current page
  const fetchServiceProviders = (page) => {
    setLoading(true);
    axios
      .get(`${config.api_path}/service-sub-categories/${id}/service-providers?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("service provider", response.data.data)
        setServiceProvider(response.data.data);
        setTotalPages(1); // Set total pages
        setLoading(false);
      })
      .catch((error) => {
        console.log("error",error)
        if(error){
          toast.error('Failed to fetch service provider. Please try again.');
          setLoading(false);
        }
        
      });
  };

  // Fetch data when component mounts or currentPage changes
  useEffect(() => {
    document.title = "Tuwe | Service Provider";
    fetchServiceProviders(currentPage);
  }, [currentPage]);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>{utils.capitalizeWords(estateName)} Services</h3>
            <h6 style={{color: "#919191",marginBottom:'20px'}}>Subcategory: {serviceProvider.name}</h6>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages"}</span>
            <Link to={`/service-provider/service/create/${id}`}><button
              type="button"
              style={{ marginBottom: '10px', float: 'right' }}
              className="btn btn-outline-primary"
            >
              New Service Provider
            </button>
</Link>
            {loading ? (
              <Spinner />
            ) : (
              <>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th></th>
                      <th>Business Name</th>
                      <th>Address</th>
                      <th>Telephone</th>
                      <th>Business Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    { serviceProvider.service_providers.data.length > 0 ? (
                      serviceProvider.service_providers.data.map((provider, index) => (
                        <tr key={provider.id}>
                          <td>{(currentPage - 1) * 10 + index + 1}</td>
                          <td><img className="img-xs rounded-circle" src={provider.image} alt="service" />
                          </td>
                          <td>
                            <Link to={`/service-provider/service/${provider.id}/view`}>{utils.capitalizeWords(provider.business_name)}</Link>
                          </td>
                          <td>{utils.capitalizeWords(provider.district.name)}</td>
                          <td>{provider.telephone_number}</td>
                          <td>{provider.type}</td>
                          <td>{ utils.statusDisplay(provider.status)}
                            </td>
                          <td>
                          <Dropdown serviceId={provider.id} onDelete={() => fetchServiceProviders(currentPage)} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No service provider found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                </div>
                <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
