import React from 'react'

const Index = ({loading}) => {
  return (
        <div className="col-md-12 mt-4" style={{display:'flex',justifyContent:'center'}}>
          <button type="submit" style={{padding:'15px 100px'}} className="btn btn-primary">
            {loading ? 'Submitting...' : 'Submit'}
          </button>
      </div>
  )
}

export default Index
