import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import { config } from '../../config/index';
import Submit from '../../components/button';
import Spinner from '../../components/spinner';
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';

const Edit = () => {
  const { id } = useParams(); // Get the ID parameter from the URL
  const [stage, setStage] = useState({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    estate_id: '',
    location: '',
    leader_name: '',
    leader_phone_number: '',
    status:'',
    image:null
  });
  const [loading, setLoading] = useState(false);

  // Effect to set document title and fetch data on initial render
  useEffect(() => {
    document.title = "Tuwe | Update Boda Stage";
    fetchStage();
  }, []);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData({ ...formData, [name]: files[0] });
    }
  };

  // Effect to update formData when stage changes
  useEffect(() => {
    setFormData({
      name: stage.name || '',
      estate_id: stage.estate_id || '',
      location: stage.location || '',
      leader_name: stage.leader_name || '',
      leader_phone_number: stage.leader_phone_number || '',
      image: stage.image||null,
      status:stage.status,
    });
  }, [stage]);

  // Function to fetch stage data
  const fetchStage = async () => {
    const token = localStorage.getItem('auth_token');
    try {
      const response = await axios.get(`${config.api_path}/boda-stages/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStage(response.data.data);
    } catch (error) {
      console.error('Error fetching stage:', error);
      toast.error(error.response.data.message);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const payload = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key ==='image'){
        if((typeof value) != 'string' ){
          payload.append(key, value)
        }
      }else{
          payload.append(key, value)
      }
    });
  
    try {
      await axios.post(`${config.api_path}/boda-stages/${id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      toast.success('Boda Stage updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

    } catch (error) {
      toast.error('Failed to update boda stage. Please try again.');
      console.error('Error updating boda stage:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 ">
        <div className="card">
          <div className="card-body">
            <h4>Update Boda Stage</h4>
            <Backbtn />
            {loading ? (
              <Spinner />
            ) : (
              <form onSubmit={handleSubmit}>
                <div className='row'>
                <section className='col-md-8'>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="col-form-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter stage name" // Added placeholder
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                    <label className="col-form-label">Location</label>
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      value={formData.location}
                      onChange={handleInputChange}
                      placeholder="Enter location" // Added placeholder
                      required
                    />
                  </div> 
                  </div>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Leader's Name</label>
                        <input
                          type="text"
                          name="leader_name"
                          className="form-control"
                          value={formData.leader_name}
                          onChange={handleInputChange}
                          placeholder="Enter leader's name" // Added placeholder
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="col-form-label">Leader's Mobile</label>
                        <input
                          type="text"
                          name="leader_phone_number"
                          className="form-control"
                          value={formData.leader_phone_number}
                          onChange={handleInputChange}
                          placeholder="Enter leader's mobile" // Added placeholder
                          required
                        />
                      </div>
                  </div>
                </section>
                <div className='col-md-4'>
                       <div className="form-group col-md-12">
                          <label>Status</label>
                          <select
                            name="status"
                            className="form-control"
                            value={formData.status}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Denied">Denied</option>

                          </select>
                        </div>
                        <ImageUploader
                            title="Boda Stage Image"
                            width="col-md-12"
                            image={formData.image}
                            onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly
                            required
                        />
                </div>
             
                </div>

                <Submit loading={loading} disabled={loading} /> {/* Disable button if loading */}
              </form>
            )}
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;
