import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner'
import Pagination from '../../components/pagination'
import Dropdown from './dropdown'
import Backbtn from '../../components/backbtn';
import utils from '../../helpers/textEdit'

const Index = () => {
 const estateID = localStorage.getItem('estateID');
 const estateName = localStorage.getItem('estateName');
  console.log(estateID)
  // State to store estate data
  const [estateNoticeBoard, setEstateNoticeBoard] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading
  const [total, setTotal] = useState(0); // State to track which dropdown is open
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem('auth_token');
  const itemsPerPage = 15; // Define how many items to display per page
  
  const fetchEstateNoticeBoard =()=>{
    axios
      .get(config.api_path+`/estates/${estateID}/estate-noticeboards`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include auth token in headers
        },
      })
      .then((response) => {
        console.log(response.data, estateID)
        setEstateNoticeBoard(response.data.noticeboards); 
        setTotal(response.data.noticeboards.length);
        setLoading(false); // Stop loading after data is fetched
      })
      .catch((error) => {
        toast.error('Failed to fetch estate noticeboard. Please try again.');
        setLoading(false); // Stop loading on error
      });
    }
  // Fetch estate data when component mounts
  useEffect(() => {
    document.title = "Tuwe | Estate NoticeBoard";
    setLoading(true); // Start loading
    fetchEstateNoticeBoard()
  }, [currentPage]);

  // Calculate the total number of pages
  const totalPages = Math.ceil(total / itemsPerPage);
  // Handle dropdown toggle

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>{utils.capitalizeWords(estateName)} NoticeBoard</h3>
            <Backbtn />

            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
             <Link to="/estate-noticeboard/create">
            <button
              type="button"
              style={{ marginBottom: '10px',float:'right' }}
              className="btn btn-outline-primary"
            >
              New Message
            </button>
            </Link>
            {/* Spinner while loading data */}
            {loading ? (
             <Spinner />
            ) : (
              <>
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Title</th>
                    <th>Sub Title</th>
                    <th>Status</th>
                    <th>Date</th>
                    
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {estateNoticeBoard.length > 0 ? (
                    estateNoticeBoard.map((estateNoticeBoard, index) => (
                      <tr key={estateNoticeBoard.id}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/estate-noticeboard/${estateNoticeBoard.id}`}>
                             {utils.capitalizeWords(utils.shorten_string(estateNoticeBoard.title),30)}
                          </Link>
                        </td>
                        <td>{utils.shorten_string(estateNoticeBoard.sub_title,30)}</td> 
                        <td>{ utils.statusDisplay(estateNoticeBoard.status) }</td>
                        <td>{new Date(estateNoticeBoard.created_at).toLocaleDateString()}</td>   
                       
                        <td>
                          <Dropdown stageId={estateNoticeBoard.id} onDelete={() => fetchEstateNoticeBoard(currentPage)} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No estates found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
             </div>
              <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
            </>
             )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
