import React from 'react';

const Index = ({ currentPage, totalPages, setCurrentPage }) => {
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;

    // Show all pages if total pages is 7 or less
    if (totalPages <= maxVisiblePages) {
      return [...Array(totalPages)].map((_, index) => index + 1);
    }

    // Show the first two, last two, and some pages around the current page
    if (currentPage <= 4) {
      pages.push(1, 2, 3, 4, 5, '...', totalPages);
    } else if (currentPage > totalPages - 4) {
      pages.push(1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
    } else {
      pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
    }

    return pages;
  };

  const pages = getPageNumbers();

  return (
    <div className="row pagination-container" style={{ marginTop: '20px' }}>
      <div className="btn-group" role="group" aria-label="Pagination Navigation">
        <button
          type="button"
          className="btn btn-outline-primary pagibutton"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        {pages.map((page, index) =>
          page === '...' ? (
            <button key={index} type="button" className="btn btn-outline-primary pagibutton" disabled>
              ...
            </button>
          ) : (
            <button
              key={page}
              type="button"
              className={`btn btn-outline-primary pagibutton ${currentPage === page ? 'active' : ''}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          )
        )}

        <button
          type="button"
          className="btn btn-outline-primary pagibutton"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Index;
