import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import { config } from '../../config';
import Submit from '../../components/button';
import Backbtn from '../../components/backbtn';
import Editor from '../../components/editor';
import ImageUploader from '../../components/imageuploader';

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const estateID = localStorage.getItem('estateID');

  const [formData, setFormData] = useState({
    title: '',
    sub_title: '',
    brief: '',
    message_body: '',
    image: '',
    status:'',
    estate_id: estateID,
  });
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false); // Track submission status

  const handleEditorBriefChange = (event, editor) => {
    const data = editor.getData();
    setFormData(prevData => ({ ...prevData, brief: data }));
  };

  const handleEditorMessageChange = (event, editor) => {
    const data = editor.getData();
    setFormData(prevData => ({ ...prevData, message_body: data }));
  };

  // Fetch the existing noticeboard details for editing
  const fetchNoticeboardDetails = async () => {
    const token = localStorage.getItem('auth_token');
    try {
      const response = await axios.get(`${config.api_path}/estate-noticeboards/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFormData(response.data.data);
    } catch (error) {
      console.error('Error fetching noticeboard details:', error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    document.title = 'Tuwe | Edit Noticeboard';
    fetchNoticeboardDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] }); // Handle the first file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Block further submissions until toast is finished
    if (submitting) return;

    setSubmitting(true); // Prevent further submissions
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const payload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key ==='image'){
        if((typeof value) != 'string' ){
          payload.append(key, value)
        }
    }else{
      payload.append(key, value)
    }
    });

    try {
      await axios.post(`${config.api_path}/estate-noticeboards/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success('Estate message updated successfully!');

      // Redirect after a short delay to allow the toast to be seen
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
      toast.error(errorMessage);
      setSubmitting(false); // Allow resubmission
      console.error('Update error:', error);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Edit Noticeboard</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              {/* First Column */}
              <div className="row">
                {/* Title */}
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    Title<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                {/* Sub Title */}
                <div className="form-group col-md-8">
                  <label className="col-form-label">
                    Sub Title<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="sub_title"
                    className="form-control"
                    value={formData.sub_title}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <Editor label="Brief" value={formData.brief} onChange={handleEditorBriefChange} />
                <div className='col-md-4'>
                        <div className="form-group col-md-12">
                          <label>Status</label>
                          <select
                            name="status"
                            className="form-control"
                            value={formData.status}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Denied">Denied</option>

                          </select>
                        </div>
                  <ImageUploader 
                      title="NoticeBoard Image" 
                      width="col-md-12"
                      image={formData.image} 
                      onChange={(e) => setFormData(prev => ({ ...prev, image: e.target.files[0] }))} 
                      required
                   />
                </div>
              </div>

              {/* Message */}
              <div className="row">
              <Editor label="Message" width="col-md-8" value={formData.message_body} onChange={handleEditorMessageChange} />
              </div>

              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;
