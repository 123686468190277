import React,{useState} from 'react'
import Nav from '../templates/nav'
import Sidebar from '../templates/sidebar/sidebar'
import EstateSidebar from '../templates/sidebar/estate_sidebar'
import PropertySidebar from '../templates/sidebar/property_sidebar'
import Footer from '../templates/footer'

const Page = ({children}) => {
  const [sideMenu, setSideMenu] = useState(true)

  const userrole = localStorage.getItem('role');

  const handleSideMenu = () => {
    setSideMenu((prev) => !prev); // use functional update
    console.log(sideMenu)
  };

  return (
    <div class=" container-scroller page-body-wrapper">
     <Nav handleSideMenu={handleSideMenu}/>
    <div class="container-fluid page-body-wrapper">
      { (userrole =='Super Admin')? <Sidebar sideMenu={sideMenu} />: 
             (userrole =='Estate Manager')? <EstateSidebar sideMenu={sideMenu}/>:
                     (userrole =='Property Agent')? <PropertySidebar sideMenu={sideMenu} />:''
      }
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
              {children}
          </div>
        </div>
       <Footer />
      </div>
    </div>
  </div>
  )
}

export default Page