import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import Spinner from '../../components/spinner';
import { config } from '../../config';
import Submit from '../../components/button';
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone_number: '',
    secondary_mobile: '',
    role_id: 0,
    estate_id: 0,
    agency_id: 0,
    status: '',
    image: null, // Start with null instead of a string
  });

  const [roles, setRoles] = useState([]);
  const [estates, setEstates] = useState([]);
  const [agency, setAgency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingUser, setFetchingUser] = useState(true);

  const handleFileChange = (name, files) => {
    if (files.length > 0) {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    }
  };

  useEffect(() => {
    document.title = "Tuwe | Edit User";
    fetchData();
  }, [id]);

  const fetchData = async () => {
    await Promise.all([fetchRoles(), fetchEstates(), fetchAgency(), fetchUserData()]);
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${config.api_path}/roles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` },
      });
      if (response.data) {
        setRoles(response.data);
      } else {
        toast.error("No roles found.");
      }
    } catch (error) {
      toast.error("Failed to fetch roles.");
      console.error(error);
    }
  };

  const fetchEstates = async () => {
    try {
      const response = await axios.get(`${config.api_path}/estates`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` },
      });
      if (response.data.data) {
        setEstates(response.data.data);
      } else {
        toast.error("No estates found.");
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  const fetchAgency = async () => {
    try {
      const response = await axios.get(config.api_path + '/agencies', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      if (response.data) {
        setAgency(response.data.data);
      } else {
        toast.error('No agency found.');
      }
    } catch (error) {
      toast.error('Failed to fetch agency.');
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${config.api_path}/users/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` },
      });
      if (response.data.data) {
        setFormData({
          name: response.data.data.name,
          email: response.data.data.email,
          phone_number: response.data.data.phone_number,
          secondary_mobile: response.data.data.secondary_mobile,
          password: '',
          password_confirmation: '',
          role_id: response.data.data.role,
          estate_id: response.data.data.estate_id,
          status: response.data.data.status,
          image: response.data.data.image||null, // Handle the image URL directly
        });
      } else {
        toast.error("User not found.");
      }
    } catch (error) {
      toast.error("Failed to fetch user data.");
      console.error(error);
    } finally {
      setFetchingUser(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleIDInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: Number(value) }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {}; // Use a plain object for the payload

    for (const key in formData) {
      if(formData[key]){
          if (key === 'image') {
              // if (typeof formData['image'] !== 'string') {
              //     payload[key] = formData[key]; // Assign the image value to the payload
              // }
          } else {
              payload[key] = formData[key]; // Assign other fields to the payload
          }
        }
    }

    try {
      await axios.put(`${config.api_path}/users/${id}`, payload, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      toast.success('User updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect after success
      }, 2000);
    } catch (error) {
      toast.error('Failed to update user. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Update User</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className='col-md-8'>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">Name<span>*</span></label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder='Fullname'
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">Email<span>*</span></label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder='Email Address'
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">Primary Mobile<span>*</span></label>
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        placeholder='Primary mobile'
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">Secondary Mobile</label>
                      <input
                        type="text"
                        name="secondary_mobile"
                        placeholder='Secondary Mobile'
                        className="form-control"
                        value={formData.secondary_mobile}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label>User Role<span>*</span></label>
                      <select
                        className="form-control"
                        name="role_id"
                        value={formData.role_id}
                        onChange={handleIDInputChange}
                        required
                      >
                        <option value="">Select Role</option>
                        {roles.length > 0 ? (
                          roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>No roles available</option>
                        )}
                      </select>
                    </div>

                    {(formData.role_id === 3 || formData.role_id === 6 || formData.role_id === 7) && (
                      <div className="form-group col-md-6">
                        <label>Estate<span>*</span></label>
                        <select
                          className="form-control"
                          name="estate_id"
                          value={formData.estate_id}
                          onChange={handleIDInputChange}
                          required
                        >
                          <option value="">Select Estate</option>
                          {estates.length > 0 ? (
                            estates.map((estate) => (
                              <option key={estate.id} value={estate.id}>
                                {estate.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No estates available</option>
                          )}
                        </select>
                      </div>
                    )}

                    {(formData.role_id === 2 || formData.role_id === 4 || formData.role_id === 5) && (
                      <div className="form-group col-md-6">
                        <label>Agency<span>*</span></label>
                        <select
                          className="form-control"
                          name="agency_id"
                          value={formData.agency_id}
                          onChange={handleIDInputChange}
                          required
                        >
                          <option value="">Select Agency</option>
                          {agency.length > 0 ? (
                            agency.map((agency) => (
                              <option key={agency.id} value={agency.id}>
                                {agency.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No agencies available</option>
                          )}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label>Password (leave empty if unchanged)</label>
                      <input
                        type="password"
                        name="password"
                        placeholder='Enter password here'
                        className="form-control"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="col-md-6 form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="password_confirmation"
                        placeholder='Confirm password here'
                        className="form-control"
                        value={formData.password_confirmation}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-group col-md-12">
                    <label>Status</label>
                    <select
                      name="status"
                      className="form-control"
                      value={formData.status}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Denied">Denied</option>
                    </select>
                  </div>
                  <ImageUploader
                    width="col-md-12"
                    title="Passport Photo"
                    image={formData.image}
                    onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))}
                  />
                </div>
              </div>
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;
