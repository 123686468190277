import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../../layouts/page';
import Spinner from '../../../../components/spinner';
import Submit from '../../../../components/button';
import { config } from '../../../../config';
import Backbtn from '../../../../components/backbtn';
import ImageUploader from '../../../../components/imageuploader';
import Editor from '../../../../components/editor';
import SelectField from '../../../../components/selectfield';
import utils from '../../../../helpers/textEdit'

const CreateHouseListing = () => {
  const { pid } = useParams();

  const [formData, setFormData] = useState({
    listing_type: '',
    agency_id:pid,
    property_type_id: '',
    address: '',
    bedrooms: '',
    bathrooms: '',
    price: '',
    description: '',
    owner_name: '',
    owner_nin_number: '',
    owner_residence: '',
    image: null,
    owner_passport_photo: null,
  });
  
  const token = localStorage.getItem('auth_token');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [propertyType, setPropertyType] = useState([]);
  const navigate = useNavigate();

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const fetchPropertyTypeData = async () => {
    try {
      const response = await axios.get(`${config.api_path}/property-types`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` },
      });
        console.log(response.data)
        setPropertyType(response.data)
    } catch (error) {
      console.error(error);
    } 
  };
  
  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = utils.formatPrice(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue
    }));
  };
  useEffect(() => {
    document.title = "Tuwe | Create House Listing";
    fetchPropertyTypeData()
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;

    setSubmitting(true);
    setLoading(true);
    const payload = new FormData();
    for (const key in formData) {
      if(key ==="price"){
           payload.append(key, utils.ReversePriceFormat(formData[key]));
      }else{
           payload.append(key, formData[key]);
      }
      
    }

    try {
      await axios.post(config.api_path+'/house-listings', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setLoading(false);
      toast.success('House listing created successfully!');
      setTimeout(() => {
        navigate(-1);
      }, 2000);
      setSubmitting(false);

    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || 'Failed to create house listing.');
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create House Listing</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
            <fieldset>
               <legend>Owner's Information</legend>
              <div className="form-row">
                <section className='col-md-8'>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label>Owner Name<span>*</span></label>
                      <input
                        type="text"
                        name="owner_name"
                        className="form-control"
                        value={formData.owner_name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Owner NIN Number<span>*</span></label>
                      <input
                        type="text"
                        name="owner_nin_number"
                        className="form-control"
                        value={formData.owner_nin_number}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                        <div className="form-group col-md-6">
                          <label>Owner Residence<span>*</span></label>
                          <input
                            type="text"
                            name="owner_residence"
                            className="form-control"
                            value={formData.owner_residence}
                            onChange={handleInputChange}
                            required
                          />
                        </div>                       
                    </div>
                    </section>
                    <section className='col-md-4'>
                       <ImageUploader
                      title="Owner's Passport Photo"
                      width="col-md-12"
                      image={formData.owner_passport_photo}
                      onChange={(e) => setFormData((prev) => ({ ...prev, owner_passport_photo: e.target.files[0] }))}
                      required
                    />
                    </section>
                   
                    </div>
                    </fieldset>
                    <fieldset>
               <legend>Property Information</legend>
              <div className="form-row">
                <section className='col-md-8'>
                   <div className="row">
                   <SelectField
                          label="Listing Type"
                          name="listing_type"
                          width="col-md-6"
                          value={formData.listing_type}
                          onChange={handleInputChange}
                          options={[{id:"rent",name:"Rent"},{id:"sale",name:"Sale"}]}
                          required
                        />
                        <SelectField
                          label="Property Type"
                          name="property_type_id"
                          width="col-md-6"
                          value={formData.property_type_id}
                          onChange={handleInputChange}
                          options={propertyType}
                          required
                        />                      
                    </div>
                    <div className='row'>
                    <div className="form-group col-md-6">
                        <label>House Bedrooms<span>*</span></label>
                        <input
                          type="number"
                          name="bedrooms"
                          className="form-control"
                          value={formData.bedrooms}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>House Bathrooms<span>*</span></label>
                        <input
                          type="number"
                          name="bathrooms"
                          className="form-control"
                          value={formData.bathrooms}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>House Price<span>*</span></label>
                        <div class="input-wrapper">
                        <input
                          type="text"
                          name="price"
                          className="form-control price-input"
                          value={formData.price}
                          onChange={handlePriceChange}
                          placeholder="0.00"
                          required
                        />
                        </div>
                        </div>
                      <div className="form-group col-md-6">
                        <label>House Address<span>*</span></label>
                        <input
                          type="text"
                          name="address"
                          className="form-control"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <Editor
                         width="col-md-12"
                          label="House Description"
                          value={formData.description}
                          onChange={handleEditorChange}
                      />
                    </div>
                  </section>
                             
                  <div className='col-md-4'>
                    
                    <ImageUploader
                      title="House Image"
                      width="col-md-12"
                      image={formData.image}
                      onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))}
                      required
                    />
                    
                  </div>
              </div>
              </fieldset>
              {/* Submit Button */}
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Submit text="Update House Listing" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Spinner />}
    </Layout>
  );
};

export default CreateHouseListing;
