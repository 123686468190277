import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner'
import Backbtn from '../../components/backbtn'

const Index = () => {
  // State to store estate data
  const [estatePay, setEstatePay] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading
  const [openDropdown, setOpenDropdown] = useState(null); // State to track which dropdown is open
  const [total, setTotal] = useState(0); // State to track which dropdown is open

  const token = localStorage.getItem('auth_token');
  const dropdownRef = useRef(null); // Ref to track dropdown element

  // Fetch estate data when component mounts
  useEffect(() => {
    document.title = "Tuwe | Estate Pay";
    setLoading(true); // Start loading

    axios
      .get(config.api_path+'/estate_pay', {
        headers: {
          Authorization: `Bearer ${token}`, // Include auth token in headers
        },
      })
      .then((response) => {
        setEstatePay(response.data.data); 
        setTotal(response.data.data.total);
        setLoading(false); // Stop loading after data is fetched
      })
      .catch((error) => {
        setLoading(false); // Stop loading on error
      });
  }, [1]);

  // Handle dropdown toggle
  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    // Bind event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Estates Pay</h3>
            <Backbtn />
            <span>{"Viewing "+total+" results"}</span>
            <button
              type="button"
              style={{ marginBottom: '10px',float:'right' }}
              className="btn btn-outline-primary"
            >
              New Pay
            </button>

            {/* Spinner while loading data */}
            {loading ? (
             <Spinner />
            ) : (
              <>
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Service</th>
                    <th>Resident</th>
                    <th>Amount</th>
                    <th>Reference</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {estatePay.length > 0 ? (
                    estatePay.map((estatePay, index) => (
                      <tr key={estatePay.id}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/estate_pay/${estatePay.id}`}>{estatePay.name}</Link>
                        </td>
                        <td>{estatePay.code}</td>
                        <td>{estatePay.nin}</td>
                        <td>{estatePay.address}</td>
                        <td>{estatePay.number_plate}</td>
                        <td>{estatePay.telephone}</td>
                        <td>
                          <div className="dropdown" ref={dropdownRef}>
                            <button
                              className="btn btn-outline-primary dropdown-toggle"
                              type="button"
                              onClick={() => toggleDropdown(estatePay.id)}
                              aria-haspopup="true"
                              aria-expanded={openDropdown === estatePay.id ? 'true' : 'false'}
                            >
                              Actions
                            </button>
                            <div
                              className={`dropdown-menu ${
                                openDropdown === estatePay.id ? 'show' : ''
                              }`}
                              aria-labelledby={`dropdownMenuButton${estatePay.id}`}
                            >
                              <Link className="dropdown-item" to={"/estates/"+estatePay.id+"/edit"}>
                                Edit
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Delete
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No estate pay record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
            </>
             )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
