import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Layout from '../../layouts/page';
import Submit from '../../components/button';
import { config } from '../../config';
import FormField from '../../components/formfield';
import SelectField from '../../components/selectfield';
import ImageUploader from '../../components/imageuploader';
import ToastNotifier from '../../components/toastnotifier';
import LoadingSpinner from '../../components/spinner';
import useFetch from '../../helpers/fetch';
import Backbtn from '../../components/backbtn';
import Editor from '../../components/editor/index';
import Articles from '../../components/articles';

const UpdateEstateForm = () => {
  const { id } = useParams();
  const token = localStorage.getItem('auth_token');
  const [formData, setFormData] = useState({
    name: '',
    district_id: '',
    constituency_id: '',
    subcounty_id: '',
    parish_id: '',
    village_id: '',
    designated_households: '',
    actual_households: '',
    estimated_population: '',
    about: '',
    image: '',
    articles: [],
    status: '',
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  // Fetching data
  const { data: districts = [], loading: loadingDistricts } = useFetch(`${config.api_path}/districts`, 0);
  const { data: constituencies = [], loading: loadingConstituencies } = useFetch(
    formData.district_id ? `${config.api_path}/districts/${formData.district_id}/constituencies` : null,
    0
  );
  const { data: subConstituencies = [], loading: loadingSubConstituencies } = useFetch(
    formData.constituency_id ? `${config.api_path}/constituencies/${formData.constituency_id}/subcounties` : null,
    0
  );
  const { data: parishes = [], loading: loadingParishes } = useFetch(
    formData.subcounty_id ? `${config.api_path}/subcounties/${formData.subcounty_id}/parishes` : null,
    0
  );
  const { data: villages = [], loading: loadingVillages } = useFetch(
    formData.parish_id ? `${config.api_path}/parishes/${formData.parish_id}/villages` : null,
    0
  );

  useEffect(() => {
    const fetchEstateData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.api_path}/estates/${id}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        const estateData = response.data.data;
        console.log(estateData);

        setFormData({
          name: estateData.name || '',
          district_id: estateData.district.id || '',
          constituency_id: estateData.constituency.id || '',
          subcounty_id: estateData.subcounty.id || '',
          parish_id: estateData.parish.id || '',
          village_id: estateData.village.id || '',
          designated_households: estateData.designated_households,
          actual_households: estateData.actual_households,
          estimated_population: estateData.estimated_population,
          about: estateData.about || '',
          image: estateData.image || '',
          status: estateData.status || '',
          articles: Array.isArray(estateData.articles) ? estateData.articles : [],
        });
        setLoading(false);
      } catch (error) {
        toast.error('Failed to load estate data.');
        setLoading(false);
      }
    };
    fetchEstateData();
  }, [id, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      about: data,
    }));
  };

  const handleArticleChange = (index, event, editor) => {
    const data = editor.getData();
    const updatedArticles = [...formData.articles];
    updatedArticles[index] = {
      ...updatedArticles[index],
      content: data,
    };
    setFormData((prevData) => ({
      ...prevData,
      articles: updatedArticles,
    }));
  };

  const addArticle = () => {
    setFormData((prevData) => ({
      ...prevData,
      articles: [...prevData.articles, { content: '', image: null }],
    }));
  };

  const removeArticle = (index) => {
    const updatedArticles = formData.articles.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      articles: updatedArticles,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    setLoading(true);

    const payload = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === 'image') {
        if (typeof formData[key] !== 'string') {
          payload.append(key, formData[key]); // Only append image if it's a file (not a string)
        }
      } else if (Array.isArray(formData[key])) {
        // Skip appending empty arrays as FormData does not handle empty arrays well
        if (formData[key].length > 0) {
          payload.append(key, JSON.stringify(formData[key])); // If it's an array, send it as a JSON string
        }
      } else {
        payload.append(key, formData[key]);
      }
    });

    // Append articles correctly (both content and image)
    formData.articles.forEach((article, index) => {
      if (article.image) {
        if(typeof article.image !='string'){
          payload.append(`articles[${index}][image]`, article.image); // Append image if present
        }
      }
      if (article.id) {
        payload.append(`articles[${index}][id]`, article.id); // Append content
      }
      if (article.title) {
        payload.append(`articles[${index}][title]`, article.title); // Append content
      }
      if (article.content) {
        payload.append(`articles[${index}][content]`, article.content); // Append content
      }
    });

    // Log the FormData to see its contents (for debugging)
    console.log(payload);

    try {
      // Send the data to the API
      await axios.post(`${config.api_path}/estates/${id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Estate updated successfully!');
      setTimeout(() => {
        navigate(-1); // Go back after a successful update
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update estate.');
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Update Estate</h3>
            <Backbtn />
            {loading ? <LoadingSpinner /> : (
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <FormField
                    label="Estate Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                  <SelectField
                    label="District"
                    name="district_id"
                    value={formData.district_id}
                    onChange={handleInputChange}
                    options={districts}
                    loading={loadingDistricts}
                    required
                  />
                  <SelectField
                    label="County"
                    name="constituency_id"
                    value={formData.constituency_id}
                    onChange={handleInputChange}
                    options={constituencies}
                    loading={loadingConstituencies}
                    required
                    disabled={!formData.district_id}
                  />
                </div>

                <div className="form-row">
                  <SelectField
                    label="Sub County"
                    name="subcounty_id"
                    value={formData.subcounty_id}
                    onChange={handleInputChange}
                    options={subConstituencies}
                    loading={loadingSubConstituencies}
                    required
                    disabled={!formData.constituency_id}
                  />
                  <SelectField
                    label="Parish"
                    name="parish_id"
                    value={formData.parish_id}
                    onChange={handleInputChange}
                    options={parishes}
                    loading={loadingParishes}
                    required
                    disabled={!formData.subcounty_id}
                  />
                  <SelectField
                    label="Village"
                    name="village_id"
                    value={formData.village_id}
                    onChange={handleInputChange}
                    options={villages}
                    loading={loadingVillages}
                    required
                    disabled={!formData.parish_id}
                  />
                  <FormField
                    label="Designated Households"
                    name="designated_households"
                    type="number"
                    value={formData.designated_households}
                    onChange={handleInputChange}
                    required
                  />
                  <FormField
                    label="Actual Households"
                    name="actual_households"
                    type="number"
                    value={formData.actual_households}
                    onChange={handleInputChange}
                    required
                  />
                  <FormField
                    label="Estimated Population"
                    name="estimated_population"
                    type="number"
                    value={formData.estimated_population}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-row">
                  <Editor
                    label="About the Estate"
                    value={formData.about}
                    onChange={handleEditorChange}
                  />
                  <div className="col-md-4">
                    <div className="form-group col-md-12">
                      <label>Status</label>
                      <select
                        name="status"
                        className="form-control"
                        value={formData.status}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Denied">Denied</option>
                      </select>
                    </div>
                    <ImageUploader
                      title="Estate Image"
                      width="col-md-12"
                      image={formData.image}
                      onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly
                      required
                    />
                  </div>
                </div>

                <Articles
                  formData={formData}
                  setFormData={setFormData}
                  handleArticleChange={handleArticleChange}
                  removeArticle={removeArticle}
                />
                <button type="button" className="btn btn-outline-primary" onClick={addArticle}>
                  Add Article
                </button>

                <Submit disabled={submitting} loading={submitting} />
              </form>
            )}
          </div>
        </div>
      </div>
      <ToastNotifier />
    </Layout>
  );
};

export default UpdateEstateForm;
