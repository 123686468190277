import {React, useEffect, useState} from 'react'
import Layout from '../../../layouts/page'
import { config } from '../../../config';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import EstatesCard from '../../../components/estate-stat-card'

const Index = () => {
  
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('auth_token');
  const estateID = localStorage.getItem('estateID');
  const estateName = localStorage.getItem('estateName');
  // Function to fetch estates
  const fetchStatistics = () => {
    setLoading(true);
    axios.get(`${config.api_path}/dashboard`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("dashboard",response.data.estate_statistics.filter(item => item.estate_id === parseInt(estateID)))
      setStatistics(response.data.estate_statistics.filter(item => item.estate_id === parseInt(estateID)))
      setLoading(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = "Tuwe | Dashboard";
    fetchStatistics();
  }, []);

  return (
    <Layout>
            <div class="row">
              <div class="col-md-12 grid-margin">
                 <EstatesCard statistics={statistics} />
                 </div>
                 <div class="row col-md-8 grid-margin">
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body pb-0">
                        <div class="d-flex justify-content-between">
                          <h4 class="card-title mb-0">Total Revenue Collected</h4>
                          <p class="font-weight-semibold mb-0">+1.37%</p>
                        </div>
                        <h3 class="font-weight-medium mb-4">0</h3>
                      </div>
                      <canvas class="mt-n4" height="90" id="total-revenue"></canvas>
                    </div>
                  </div>
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body pb-0">
                        <div class="d-flex justify-content-between">
                          <h4 class="card-title mb-0">E-Pay Transactions</h4>
                          <p class="font-weight-semibold mb-0">-2.87%</p>
                        </div>
                        <h3 class="font-weight-medium">0 </h3>
                      </div>
                      <canvas class="mt-n3" height="90" id="total-transaction"></canvas>
                    </div>
                  </div>
                  <div class="col-md-12 grid-margin">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <h4 class="card-title mb-0">Collected Revenue by Activity</h4>
                          <a href="#"><small>Show All</small></a>
                        </div>
                        <p>These are the ongoing activities run by the estate</p>
                        <div class="table-responsive">
                          <table class="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Activity</th>
                                <th>Status</th>
                                <th>Due Date</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>INV-87239</td>
                                <td>Viola Ford</td>
                                <td>Paid</td>
                                <td>20 Jan 2019</td>
                                <td>$755</td>
                              </tr>
                              <tr>
                                <td>INV-87239</td>
                                <td>Dylan Waters</td>
                                <td>Unpaid</td>
                                <td>23 Feb 2019</td>
                                <td>$800</td>
                              </tr>
                              <tr>
                                <td>INV-87239</td>
                                <td>Louis Poole</td>
                                <td>Unpaid</td>
                                <td>25 Mar 2019</td>
                                <td>$463</td>
                              </tr>
                              <tr>
                                <td>INV-87239</td>
                                <td>Vera Howell</td>
                                <td>Paid</td>
                                <td>27 Mar 2019</td>
                                <td>$235</td>
                              </tr>
                              <tr>
                                <td>INV-87239</td>
                                <td>Allie Goodman</td>
                                <td>Unpaid</td>
                                <td>1 Apr 2019</td>
                                <td>$657</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title mb-0">Recent Activities</h3>
                    <div class="d-flex py-2 border-bottom">
                      <div class="wrapper">
                        <small class="text-muted">Mar 14, 2019</small>
                        <p class="font-weight-semibold text-gray mb-0">Change in Directors</p>
                      </div>
                      <small class="text-muted ml-auto">View event</small>
                    </div>
                    <div class="d-flex py-2 border-bottom">
                      <div class="wrapper">
                        <small class="text-muted">Mar 14, 2019</small>
                        <p class="font-weight-semibold text-gray mb-0">Other Events</p>
                      </div>
                      <small class="text-muted ml-auto">View event</small>
                    </div>
                    <div class="d-flex py-2 border-bottom">
                      <div class="wrapper">
                        <small class="text-muted">Mar 14, 2019</small>
                        <p class="font-weight-semibold text-gray mb-0">Quarterly Report</p>
                      </div>
                      <small class="text-muted ml-auto">View event</small>
                    </div>
                    <div class="d-flex pt-2">
                      <div class="wrapper">
                        <small class="text-muted">Mar 14, 2019</small>
                        <p class="font-weight-semibold text-gray mb-0">Change in Directors</p>
                      </div>
                      <small class="text-muted ml-auto">View event</small>
                    </div>
                    <a class="d-block mt-5" href="#">Show all</a>
                  </div>
                </div>
              </div>
             
                               </div>
    </Layout>
    
  )
}

export default Index