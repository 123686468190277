import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page'; // Adjust path to match your folder structure
import Submit from '../../components/button'; // Adjust path to match your folder structure
import { config } from '../../config'; // Replace with actual API config
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';
import Editor from '../../components/editor';

const Create = () => {

  const token = localStorage.getItem('auth_token');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    type:'Property Agency',
    description:'',
    logo:null
  });
  const [selectedLogo, setSelectedLogo] = useState(null); // To handle logo file
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create FormData for file upload and other form data
    const payload = new FormData();
    for (const key in formData) {
      payload.append(key, formData[key]);
    }

    try {
      // Replace with your API path
      await axios.post(`${config.api_path}/agencies`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      toast.success('Agency created successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

      // Reset form after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        description:'',
        type:'Property Agency'
      });
      setSelectedLogo(null); // Reset the logo file
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create Property Agency</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              {/* Form Row for Name and Email */}
              
              <div className="form-row">
                <section className='col-md-8'>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Agency Name<span>*</span></label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={formData.name}
                          placeholder='Enter Agency Name'
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email<span>*</span></label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder='Enter Email address'
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label htmlFor="phone">Phone<span>*</span></label>
                        <input
                          type="text"
                          name="phone"
                          placeholder='Enter Phone number'
                          className="form-control"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="address">Address<span>*</span></label>
                        <input
                          type="text"
                          name="address"
                          placeholder='Enter Agency Address'
                          className="form-control"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Country</label>
                  <select
                    name="country"
                    className="form-control"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Country</option>
                    <option value="uganda">Uganda</option>
                    <option value="kenya">Kenya</option>
                    <option value="tanzania">Tanzania</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">City<span>*</span></label>
                  <input
                    type="text"
                    name="city"
                    placeholder='Enter city'
                    className="form-control"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='row'>
                  <Editor
                      width="col-md-12"
                      label="Description"
                      value={formData.description}
                      onChange={handleEditorChange}
                    />
              </div>
            </section>             
            <ImageUploader
              title={`Property Agency logo`}
              image={formData.logo}
              onChange={(e) => setFormData((prev) => ({ ...prev, logo: e.target.files[0] }))} // Change to handle the file directly

            />
          </div>

              {/* Submit Button */}
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
