import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ImageUploader = ({ title, image, width="col-md-4", onChange, required }) => {
  const fileInputRef = useRef(null); // Create a ref for the file input
  
  if (image instanceof File){
      image =URL.createObjectURL(image)
  }

  // Log the current image URL when it changes
  useEffect(() => {
    console.log("Current image URL:", image);
  }, [image]);

  // Handle the button click to trigger the file input
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Programmatically click the hidden input
  };

  return (
    <div className={`form-group ${width}`} style={{ display: 'flex', padding:"0 20px ",flexDirection: 'column' }}>
      <label >
        {title} Upload
        {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={onChange}
        style={{ display: 'none' }} // Hide the input
        accept="image/*" // Restrict file types to images
      />
      <img src={image? image:"/images/placeholder.jpg"} alt="Uploaded" 
          style={{ maxWidth: '100%', height:'140px',borderRadius:'4px' }} />
        <button
        type="button"
        onClick={handleButtonClick}
        className="btn btn-primary mt-2 uploadbtn"
      ><i className="mdi mdi-upload"></i>
      </button>
    </div>
  );
};

// PropTypes for validation
ImageUploader.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default ImageUploader;
