import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../components/pagination';
import Backbtn from '../../components/backbtn';
import utils from '../../helpers/textEdit'

const Index = () => {
  const { id } = useParams();
  const [estateBoda, setEstateBoda] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const itemsPerPage = 15;

  useEffect(() => {
    document.title = "Tuwe | Estate Boda";
    fetchEstateBoda(currentPage);
  }, [currentPage]);

  const fetchEstateBoda = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/boda-stages/${id}/estate-bodas?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data.data;
      setEstateBoda(data);
      setTotal(data.estate_bodas?.meta?.total || 0); // Set total from meta data
      setLoading(false);
    } catch (error) {
      console.error("Error:", error); 
      toast.error('Failed to fetch estate Boda. Please try again.');
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Estate Bodas</h3>
            
            <h6 style={{ color: "#919191" }}>Stage: {estateBoda.name || 'N/A'}</h6>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to={`/estate-boda/create/${id}`}>
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Estate Boda
              </button>
            </Link>
            {loading ? (
              <Spinner />
            ) : (
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Full Name</th>
                    <th>Call Name</th>
                    <th>District</th>
                    <th>Plate Number</th>
                    <td>Status</td>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {estateBoda.estate_bodas?.data?.length > 0 ? (
                    estateBoda.estate_bodas.data.map(boda => (
                      <tr key={boda.id}>
                        <td>{boda.id}</td>
                        <td><img className="img-xs rounded-circle" src={boda.passport_photo} alt="boda"/></td>
                        <td><Link to={`/estate-boda/${boda.id}/view`}>{boda.full_name}</Link></td>
                        <td>{boda.call_name}</td>
                        <td>{boda.district?.name || 'N/A'}</td> {/* Access district.name safely */}
                        <td>{boda.number_plate}</td>
                        <td>{ utils.statusDisplay(boda.status) }</td>
                        <td>
                          <Dropdown estateId={boda.id} onDelete={() => fetchEstateBoda(currentPage)} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">No estate bodas found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
            )}
            <Pagination 
              currentPage={currentPage} 
              totalPages={totalPages} 
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
