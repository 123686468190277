import React, { useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page'; // Adjust path to match your folder structure
import Submit from '../../components/button'; // Adjust path to match your folder structure
import { config } from '../../config'; // Replace with actual API config
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';

const Edit = () => {
  const { id } = useParams(); // Get agency ID from URL parameters
  const token = localStorage.getItem('auth_token');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    logo:null,
    type:'Estate Agency'
  });
  const [loading, setLoading] = useState(false);

   // Fetch the current agency details when the component loads
   useEffect(() => {
    const fetchAgencyDetails = async () => {
      try {
        const token = localStorage.getItem('auth_token'); // Retrieve the auth token
        const response = await axios.get(`${config.api_path}/agencies/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token in the request header
          },
        });
        const { name, email, phone, address, city, country,logo } = response.data.data;
        setFormData({ name, email, phone, address, city, country,logo });
      } catch (error) {
        toast.error('Failed to load agency details.');
      }
    };

    fetchAgencyDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create FormData for file upload and other form data
    const payload = new FormData();
    for (const key in formData) {
      if (key ==='logo'){
        if((typeof formData[key]) != 'string' ){
          payload.append(key, formData[key])
        }
    }else{
      payload.append(key, formData[key])
    }
    }

    try {
      // Replace with your API path
      await axios.post(`${config.api_path}/agencies`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      toast.success('Agency created successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

      // Reset form after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        logo:null,
        type:'Estate Agency'
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4>Create Estates Agency</h4>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              {/* Form Row for Name and Email */}
              
              <div className="form-row">
                <section className='col-md-8'>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Agency Name<span>*</span></label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={formData.name}
                          placeholder='Enter Agency Name'
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email<span>*</span></label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder='Enter Email address'
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label htmlFor="phone">Phone<span>*</span></label>
                        <input
                          type="text"
                          name="phone"
                          placeholder='Enter Phone number'
                          className="form-control"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="address">Address<span>*</span></label>
                        <input
                          type="text"
                          name="address"
                          placeholder='Enter Agency Address'
                          className="form-control"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
                  <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Country</label>
                  <select
                    name="country"
                    className="form-control"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Country</option>
                    <option value="uganda">Uganda</option>
                    <option value="kenya">Kenya</option>
                    <option value="tanzania">Tanzania</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">City<span>*</span></label>
                  <input
                    type="text"
                    name="city"
                    placeholder='Enter city'
                    className="form-control"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            </section>             
            <ImageUploader
              title={`Estate Agency logo`}
              image={formData.logo}
              onChange={(e) => setFormData((prev) => ({ ...prev, logo: e.target.files[0] }))} // Change to handle the file directly

            />
          </div>

              {/* Submit Button */}
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;
