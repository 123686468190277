import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Sidebar = ({sideMenu}) => {
    const username = localStorage.getItem('username');
    const userrole = localStorage.getItem('role');
    const estateName = localStorage.getItem('estateName');
    const location = useLocation(); // Hook to get current URL path

    // State for toggling dropdown menus
    const [isEstateOpen, setEstateOpen] = useState(false);
    const [isUsersOpen, setUsersOpen] = useState(false);

    // Refs for submenu containers
    const estateMenuRef = useRef(null);
    const usersMenuRef = useRef(null);

    // Function to determine if the current link is active
    const isActive = (path) => location.pathname === path;

    // Check if any submenu item under Digital Broker is active
    const isEstateSubmenuActive = ['/estate-members', '/estate-noticeboard', '/estate-pay', '/estate-boda-stage','/service-provider/category','/estates/activities'].includes(location.pathname);

    // Check if any submenu item under Users Management is active
    const isUsersSubmenuActive = ['/users', '/users/audit-trail'].includes(location.pathname);

    // Automatically open dropdown if a submenu item is active
    useEffect(() => {
        if (isEstateSubmenuActive) setEstateOpen(true);
        if (isUsersSubmenuActive) setUsersOpen(true);
    }, [isEstateSubmenuActive, isUsersSubmenuActive]);



    const toggleUsersDropdown = () => {
        setUsersOpen(!isUsersOpen);
        if (usersMenuRef.current) {
            usersMenuRef.current.style.height = isUsersOpen ? '0px' : `${usersMenuRef.current.scrollHeight}px`;
        }
    };

    const toggleEstateDropdown = () => {
        setEstateOpen(!isEstateOpen);
        if (estateMenuRef.current) {
            estateMenuRef.current.style.height = isEstateOpen ? '0px' : `${estateMenuRef.current.scrollHeight}px`;
        }
    };

    // Update height when state or content changes
    useEffect(() => {
        if (estateMenuRef.current) {
            estateMenuRef.current.style.height = isEstateOpen ? `${estateMenuRef.current.scrollHeight}px` : '0px';
        }
        if (usersMenuRef.current) {
            usersMenuRef.current.style.height = isUsersOpen ? `${usersMenuRef.current.scrollHeight}px` : '0px';
        }
    }, [isEstateOpen, isUsersOpen]);

    return (
        <nav className={(sideMenu)? "sidebar sidebar-offcanvas":"sidebar"}  id="sidebar">
            
            <ul className="nav">
                <li className="nav-item nav-profile"><h4 style={{color:"#fff"}}>{ estateName.toLocaleUpperCase() }</h4>
                    <a href="#" className="nav-link">
                        <div className="profile-image">
                            <img className="img-xs rounded-circle" src="../../assets/images/faces/face8.jpg" alt="profile" />
                            <div className="dot-indicator bg-success"></div>
                        </div>
                        <div className="text-wrapper">
                            <p className="profile-name">{username}.</p>
                            <span>{userrole}</span>
                        </div>
                    </a>
                </li>
                <li className="nav-item nav-category">Main Menu</li>

                {/* Dashboard */}
                <li className={`nav-item ${isActive('/dashboard') ? 'active' : ''}`}>
                    <a className="nav-link" href="/dashboard">
                        <i className="menu-icon fas fa-tachometer-alt"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Dashboard</span>
                    </a>
                </li>
               {/* Users Management dropdown */}
               <li className="nav-item">
                    <a className="nav-link" onClick={toggleEstateDropdown} aria-expanded={isEstateOpen || isUsersSubmenuActive}>
                        <i className="menu-icon fas fa-users-cog"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Estate Management</span>
                        <i className={`menu-arrow ${isEstateOpen || isEstateSubmenuActive ? 'open' : ''}`}></i>
                    </a>
                    <div
                        className={`collapse ${isEstateOpen || isEstateSubmenuActive ? 'show' : ''}`}
                        id="estate"
                        ref={estateMenuRef}
                    >
                        <ul className="nav flex-column sub-menu">
                        {/* Residential Estates */}
                        <li className={`nav-item ${isActive('/estate-members') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/estate-members">
                                <i className="menu-icon fas fa-building"></i> {/* Font Awesome icon */}
                                <span className="menu-title">Members</span>
                            </Link>
                        </li>
                        <li className={`nav-item ${isActive('/estate-noticeboard') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/estate-noticeboard">
                                <i className="menu-icon fas fa-building"></i> {/* Font Awesome icon */}
                                <span className="menu-title">Noticeboard</span>
                            </Link>
                        </li>

                        <li className={`nav-item ${isActive('/estate-pay') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/estate-pay">
                                <i className="menu-icon fas fa-building"></i> {/* Font Awesome icon */}
                                <span className="menu-title">E-Pay</span>
                            </Link>
                        </li>
                        <li className={`nav-item ${isActive('/estate-boda-stage') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/estate-boda-stage">
                                <i className="menu-icon fas fa-building"></i> {/* Font Awesome icon */}
                                <span className="menu-title">Boda Stage</span>
                            </Link>
                        </li>
                        <li className={`nav-item ${isActive('/service-provider/category') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/service-provider/category">
                                <i className="menu-icon fas fa-building"></i> {/* Font Awesome icon */}
                                <span className="menu-title">Service Providers</span>
                            </Link>
                        </li>
                        <li className={`nav-item ${isActive('/estates/activities') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/estates/activities">
                                <i className="menu-icon fas fa-building"></i> {/* Font Awesome icon */}
                                <span className="menu-title">Activities</span>
                            </Link>
                        </li>
                        </ul>
                        </div>
                </li>
                {/* Users Management dropdown */}
                <li className="nav-item">
                    <a className="nav-link" onClick={toggleUsersDropdown} aria-expanded={isUsersOpen || isUsersSubmenuActive}>
                        <i className="menu-icon fas fa-users-cog"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Users Management</span>
                        <i className={`menu-arrow ${isUsersOpen || isUsersSubmenuActive ? 'open' : ''}`}></i>
                    </a>
                    <div
                        className={`collapse ${isUsersOpen || isUsersSubmenuActive ? 'show' : ''}`}
                        id="users"
                        ref={usersMenuRef}
                    >
                        <ul className="nav flex-column sub-menu">
                            <li className={`nav-item ${isActive('/users') ? 'active' : ''}`}>
                                <Link className="nav-link" to="/users">
                                    <i className="menu-icon fas fa-user"></i> {/* Font Awesome icon */}
                                    <span className="menu-title">Users</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/users/audit-trail') ? 'active' : ''}`}>
                                <Link className="nav-link" to="/users/audit-trail">
                                    <i className="menu-icon fas fa-file-alt"></i> {/* Font Awesome icon */}
                                    <span className="menu-title">Audit Trail</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                {/* Settings */}
                <li className={`nav-item ${isActive('/settings') ? 'active' : ''}`}>
                    <Link className="nav-link" to="/settings">
                        <i className="menu-icon fas fa-cog"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Settings</span>
                    </Link>
                </li>

                {/* Support */}
                <li className={`nav-item ${isActive('/support') ? 'active' : ''}`}>
                    <Link className="nav-link" to="/support">
                        <i className="menu-icon fas fa-life-ring"></i> {/* Font Awesome icon */}
                        <span className="menu-title">Support</span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Sidebar;
