import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../../config';
import Spinner from '../../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../../components/pagination'
import Backbtn from '../../../components/backbtn';
import utils from '../../../helpers/textEdit'

const Index = () => {
   const { id } = useParams()
  const [subCategory, setSubCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const dropdownRef = useRef(null);
  const itemsPerPage = 10; // Define how many items to display per page

  useEffect(() => {
    document.title = "Tuwe | Estate Boda";
    fetchSubCategory(currentPage);
  }, [currentPage]);

  const fetchSubCategory = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/service-categories/${id}/service-sub-categories?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("subcategory",response.data.data)
      setSubCategory(response.data.data);
      setTotal(1);
      setLoading(false);
    } catch (error) {
      console.error("error",error); 
      toast.error('Failed to fetch service sub categories. Please try again.');
      setLoading(false);
    }
  };

  const escapeHtmlTags = (text) => {
    return text.replace(/<\/?p>/g, '');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Calculate the total number of pages
  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Sub Categories</h3>
          
          <h6 style={{color: "#919191",marginBottom:'20px'}}>Category: {subCategory.name}</h6> 
           <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to={`/service-provider/subcategory/create/${id}`}>
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Sub Category
              </button>
            </Link>
            {loading ? (
              <Spinner />
            ) : (
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subCategory.service_sub_categories.data.map(boda => (
                    <tr key={boda.id}>
                      <td>{boda.id}</td>
                      <td><Link to={`/service-provider/service/${boda.id}`}>{utils.capitalizeWords(boda.name)}</Link></td>
                      <td>{escapeHtmlTags(boda.description)}</td>
                      <td>
                        <Dropdown serviceId={boda.id} onDelete={() => fetchSubCategory(currentPage)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}

               <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
