
function shorten_string(word, num){
    if(word.length > num){
        return word.substring(0,num)+"..."
    }
   return word
}

function capitalizeWords(words) {
    return words
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function dateToNormal(date){
   return new Date(date).toLocaleDateString()
}

function statusDisplay(status){
    if(status ==="Pending"){
        return (
            <label class="badge badge-info">
               Pending
            </label>
        )
    }else if(status ==="Approved"){
        return (
            <label class="badge badge-success">
               Approved
            </label>
        )
    }else if(status ==="Denied"){
        return (
            <label class="badge badge-danger">
               Denied
            </label>
        )
    }
}

// Price formatting handler
const formatPrice = (value) => {
    // Remove any non-numeric characters except the dot
    let formattedValue = value.replace(/[^0-9.]/g, '');

    // Add commas as thousands separators
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Format integer part with commas

    // Rebuild the value
    return parts.join('.');
};

// Price formatting handler
const ReversePriceFormat = (value) => {
    // Remove any non-numeric characters except the dot
    return value.replace(/[^0-9]/g, '');
};
export default { shorten_string, formatPrice, ReversePriceFormat, statusDisplay, dateToNormal, capitalizeWords };
