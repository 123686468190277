import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../layouts/page';
import Submit from '../../../components/button';
import Editor from '../../../components/editor/index';
import { config } from '../../../config';
import Backbtn from '../../../components/backbtn';


const EditServiceSubCategory = () => {
  const { id} = useParams()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    service_category_id: '',
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);

  // Endpoint to get a single sub-category's details and update the sub-category
  const getUrl = config.api_path+`/service-sub-categories/${id}`;
  const updateUrl = config.api_path+`/service-sub-categories/${id}`;

  // Fetch existing sub-category details
  const fetchSubCategoryDetails = async () => {
    const token = localStorage.getItem('auth_token');
    try {
      const response = await axios.get(getUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data.data;
      console.log(data)
      setFormData({
        service_category_id: data.category_id || '',
        name: data.name || '',
        description: data.description || '',
      });
    } catch (error) {
      console.error('Error fetching sub-category details:', error);
      toast.error(error.response.data.message);
    }
  };

  // Fetch all service categories for the dropdown
  const fetchServiceCategories = async () => {
    const token = localStorage.getItem('auth_token');
    try {
      const response = await axios.get(config.api_path+'/service-categories', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setServiceCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching service categories:', error);
      toast.error('Failed to load service categories. Please try again later.');
    }
  };

  // Load sub-category details and service categories when the component is mounted
  useEffect(() => {
    document.title = 'Edit Service Sub-Category';
    fetchSubCategoryDetails();
    fetchServiceCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    try {
      await axios.put(updateUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setLoading(false);
      toast.success('Service sub-category updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

    } catch (error) {
      setLoading(false);
      toast.error('Failed to update service sub-category. Please try again.');
      console.error('Update error:', error);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4>Edit Service Sub-Category</h4>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* Name */}
                <div className="form-group col-md-6">
                  <label className="col-form-label">
                    Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <Editor
                    width="col-md-12"
                    label="Description"
                    value={formData.description}
                    onChange={handleEditorChange}
                  />
              </div>

              {/* Submit Button */}
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditServiceSubCategory;
