import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { config } from '../../config/';
import { ToastContainer, toast } from 'react-toastify';
import Dropdown from './dropdown/index';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/spinner';
import Pagination from '../../components/pagination'
import utils from '../../helpers/textEdit'


const Index = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const token = localStorage.getItem('auth_token');

  // Function to fetch users with pagination
  const fetchUsers = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/users?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setTotal(response.data.meta.total)
      setUsers(response.data.data);
      setCurrentPage(response.data.meta.current_page); // Set current page from API
      setTotalPages(response.data.meta.last_page); // Set total pages from API
      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching the users!", error);
      toast.error("There was an error fetching the users!");
      setLoading(false);
    }
  };

  // Fetch users on component mount or when the current page changes
  useEffect(() => {
    document.title = "Tuwe | User Management";
    fetchUsers(currentPage);
  }, [currentPage, token]);

  // Function to refresh users
  const refreshUsers = () => {
    fetchUsers(currentPage); // Refetch the users for the current page
  };

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>User Management</h3>
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to="/users/create">
              <button type="button" style={{ marginBottom: "10px", float:'right' }} className="btn btn-outline-primary">
                New User
              </button>
            </Link>
            {loading ? (
              <Spinner />
            ) : (
              <>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th></th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Date created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      users.map((user, index) => (
                        <tr key={user.id}>
                          <td>{(currentPage - 1) * 10 + index + 1}</td>
                          <th><img class="img-xs rounded-circle" src={user.image} alt="user"/></th>
                          <td><Link to={`/users/${user.id}`}>{user.name}</Link></td>
                          <td>{user.email}</td>
                          <td>{utils.statusDisplay(user.status)}</td>
                          <td>{new Date(user.created_at).toLocaleDateString()}</td>
                          <td>
                            <Dropdown user={user} onDisable={refreshUsers} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">No users found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                </div>
                <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
              </>
            )}
          </div>
          <ToastContainer />
        </div>
      </div>
    </Layout>
  );
};

export default Index;
