import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../../../../config/index'

// Custom Dropdown Component
const Index = ({ estateId, onDelete, pid }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`#dropdown-${estateId}`)) {
        setIsOpen(false);
      }
    };

    // Add event listener to close dropdown on outside click
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [estateId]);

  // Function to handle deletion
  const handleDelete = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this estate?');
    if (confirmed) {
      const token = localStorage.getItem('auth_token'); // Assuming you stored the token in local storage
      try {
        const response = await axios.delete(config.api_path+`/estate-bodas/${estateId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token in the Authorization header
          },
        });

        if (response.status === 200) {
          toast.success('Estate Boda deleted successfully!'); // Show success toast
          onDelete(estateId); // Call the onDelete function passed from the parent
        } else {
          toast.error('Failed to delete estate. Please try again.'); // Show error toast
        }
      } catch (error) {
        console.error('Error deleting estate boda:', error);
        toast.error('An error occurred while deleting the estate.'); // Show error toast
      }
    }
  };

  return (
    <div className="dropdown" id={`dropdown-${estateId}`} style={{ position: 'relative' }}>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        Actions
      </button>
      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', position: 'absolute', top: '100%', zIndex: 1 }}>
        <Link className="dropdown-item" to={`/property-agency/${pid}/houses-listed/${estateId}/edit`}>Edit</Link>
        <button className="dropdown-item" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default Index;
