import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Layout from '../../layouts/page';
import Submit from '../../components/button';
import { config } from '../../config';
import FormField from '../../components/formfield';
import SelectField from '../../components/selectfield';
import ImageUploader from '../../components/imageuploader';
import ToastNotifier from '../../components/toastnotifier';
import LoadingSpinner from '../../components/spinner';
import Backbtn from '../../components/backbtn';
import useFetch from '../../helpers/fetch';
import Editor from '../../components/editor/index';
import Articles from '../../components/articles'

const CreateEstateForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    district_id: '',
    constituency_id: '',
    subcounty_id: '',
    parish_id: '',
    village_id: '',
    designated_households: '',
    actual_households: '',
    estimated_population: '',
    about: '',
    image: null,
    articles: [],
  });

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  // Fetching data
  const { data: districts = [], loading: loadingDistricts } = useFetch(`${config.api_path}/districts`, 0);
  const { data: constituencies = [], loading: loadingConstituencies } = useFetch(
    formData.district_id ? `${config.api_path}/districts/${formData.district_id}/constituencies` : null, 0
  );
  const { data: subConstituencies = [], loading: loadingSubConstituencies } = useFetch(
    formData.constituency_id ? `${config.api_path}/constituencies/${formData.constituency_id}/subcounties` : null, 0
  );
  const { data: parishes = [], loading: loadingParishes } = useFetch(
    formData.subcounty_id ? `${config.api_path}/subcounties/${formData.subcounty_id}/parishes` : null, 0
  );
  const { data: villages = [], loading: loadingVillages } = useFetch(
    formData.parish_id ? `${config.api_path}/parishes/${formData.parish_id}/villages` : null, 0
  );
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      about: data,
    }));
  };

  const handleArticleChange = (index, event, editor) => {
    const data = editor.getData();
    const updatedArticles = [...formData.articles];
    updatedArticles[index] = {
      ...updatedArticles[index],
      content: data,
    };
    setFormData((prevData) => ({
      ...prevData,
      articles: updatedArticles,
    }));
  };

  const addArticle = () => {
    setFormData((prevData) => ({
      ...prevData,
      articles: [...prevData.articles, { title: '', content: '', image: null, imageUrl: null }],
    }));
  };

  const removeArticle = (index) => {
    const updatedArticles = formData.articles.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      articles: updatedArticles,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    setLoading(true);

    const payload = new FormData();
    Object.keys(formData).forEach((key) => payload.append(key, formData[key]));
    if (formData.image) payload.append('image', formData.image);
    if(formData.articles.length > 0){
       formData.articles.forEach((article, index) => {
          payload.append(`articles[${index}][title]`, article.title);
          payload.append(`articles[${index}][content]`, article.content);
          if (article.image) {
            payload.append(`articles[${index}][image]`, article.image);
          }
       });
    }
   
    console.log(payload)
    try {
      await axios.post(`${config.api_path}/estates`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      toast.success('Estate created successfully!');
      setFormData({
        name: '',
        district_id: '',
        constituency_id: '',
        subcounty_id: '',
        parish_id: '',
        village_id: '',
        designated_households: '',
        actual_households: '',
        estimated_population: '',
        about: '',
        articles: [],
      });
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      const message = error.response?.data?.message || 'An error occurred. Please try again.';
      toast.error(message);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };
  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3>Create Estate</h3>
            <Backbtn />
            {loading ? <LoadingSpinner /> : (
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <FormField
                  label="Estate Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <SelectField
                  label="District"
                  name="district_id"
                  value={formData.district_id}
                  onChange={handleInputChange}
                  options={districts}
                  loading={loadingDistricts}
                  required
                />
                <SelectField
                  label="County"
                  name="constituency_id"
                  value={formData.constituency_id}
                  onChange={handleInputChange}
                  options={constituencies}
                  loading={loadingConstituencies}
                  required
                  disabled={!formData.district_id}
                />
              </div>

              <div className="form-row">
                <SelectField
                  label="Sub County"
                  name="subcounty_id"
                  value={formData.subcounty_id}
                  onChange={handleInputChange}
                  options={subConstituencies}
                  loading={loadingSubConstituencies}
                  required
                  disabled={!formData.constituency_id}
                />
                <SelectField
                  label="Parish"
                  name="parish_id"
                  value={formData.parish_id}
                  onChange={handleInputChange}
                  options={parishes}
                  loading={loadingParishes}
                  required
                  disabled={!formData.subcounty_id}
                />
                <SelectField
                  label="Village"
                  name="village_id"
                  value={formData.village_id}
                  onChange={handleInputChange}
                  options={villages}
                  loading={loadingVillages}
                  required
                  disabled={!formData.parish_id}
                />
                <FormField
                  label="Designated Households"
                  name="designated_households"
                  type="number"
                  value={formData.designated_households}
                  onChange={handleInputChange}
                  required
                />
                <FormField
                  label="Actual Households"
                  name="actual_households"
                  type="number"
                  value={formData.actual_households}
                  onChange={handleInputChange}
                  required
                />
                <FormField
                  label="Estimated Population"
                  name="estimated_population"
                  type="number"
                  value={formData.estimated_population}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-row">
                <Editor
                  label="About the Estate"
                  value={formData.about}
                  onChange={handleEditorChange}
                />
                <ImageUploader
                  title="Estate Image"
                  image={formData.image}
                  onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly
                  required
                />
              </div>
               <Articles
                  formData={formData}
                  setFormData={setFormData}
                  handleArticleChange={handleArticleChange}
                  removeArticle={removeArticle}
               />
              <button type="button" className="btn btn-outline-primary" onClick={addArticle}>
                Add Article
              </button>
              <Submit disabled={submitting} loading={submitting} />
            </form>
            )}
          </div>
        </div>
      </div>
      <ToastNotifier />
    </Layout>
  );
};

export default CreateEstateForm;
