import React from 'react';

const FormField = ({ label, name, value, width="col-md-4",onChange, type = 'text', required = false }) => (
  <div className={`form-group ${width}`}>
    <label >
      {label}{required && <span>*</span>}
    </label>
    <input
      type={type}
      name={name}
      className="form-control"
      value={value}
      onChange={onChange}
      required={required}
    />
  </div>
);

export default FormField;
