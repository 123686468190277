import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // For fetching agency ID and navigation
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page'; // Adjust path
import Submit from '../../components/button'; // Adjust path
import { config } from '../../config'; // Replace with actual API config
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';
import Editor from '../../components/editor';

const EditAgency = () => {
  const { id } = useParams(); // Get agency ID from URL parameters
  const navigate = useNavigate(); // To navigate after successful edit

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    status:'',
    description:'',
    logo:null
  });
  const [loading, setLoading] = useState(false);

  // Fetch the current agency details when the component loads
  useEffect(() => {
    const fetchAgencyDetails = async () => {
      try {
        const token = localStorage.getItem('auth_token'); // Retrieve the auth token
        const response = await axios.get(`${config.api_path}/agencies/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token in the request header
          },
        });
        const { name, email, phone, address, city, country,logo, status,description } = response.data.data;
        setFormData({ name, email, phone, address, city, country,logo,status, description});
      } catch (error) {
        toast.error('Failed to load agency details.');
      }
    };
    fetchAgencyDetails();
  }, [id]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Retrieve the token from localStorage
    const token = localStorage.getItem('auth_token');
    // Create FormData for file upload and other form data
    const payload = new FormData();
    for (const key in formData) {
        if (key ==='logo'){
            if((typeof formData[key]) != 'string' ){
              payload.append(key, formData[key])
            }
        }else{
            payload.append(key, formData[key])
        }
    }
    console.log(payload)
    try {
      // Send a PUT request to update the agency details with Bearer token authentication
      await axios.post(`${config.api_path}/agencies/${id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`, // Include Bearer token in headers
        },
      });
      setLoading(false);
      toast.success('Agency updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h3 style={{textAlign:'center'}}>Update Property Agency</h3>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              {/* Form Row for Name and Email */}
              
              <div className="form-row">
                <section className='col-md-8'>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Agency Name<span>*</span></label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={formData.name}
                          placeholder='Enter Agency Name'
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email<span>*</span></label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder='Enter Email address'
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
                  <div className='row'>
                      <div className="form-group col-md-6">
                        <label htmlFor="phone">Phone<span>*</span></label>
                        <input
                          type="text"
                          name="phone"
                          placeholder='Enter Phone number'
                          className="form-control"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="address">Address<span>*</span></label>
                        <input
                          type="text"
                          name="address"
                          placeholder='Enter Agency Address'
                          className="form-control"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                  </div>
                  <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Country</label>
                  <select
                    name="country"
                    className="form-control"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Country</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">City<span>*</span></label>
                  <input
                    type="text"
                    name="city"
                    placeholder='Enter city'
                    className="form-control"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='row'>
                  <Editor
                      width="col-md-12"
                      label="Description"
                      value={formData.description}
                      onChange={handleEditorChange}
                    />
              </div>
            </section> 
            <div className='col-md-4'>
                       <div className="form-group col-md-12">
                          <label>Status</label>
                          <select
                            name="status"
                            className="form-control"
                            value={formData.status}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Denied">Denied</option>
                          </select>
                        </div>
                        <ImageUploader
                          width="col-md-12"
                          title={`Property Agency logo`}
                          image={formData.logo}
                          onChange={(e) => setFormData((prev) => ({ ...prev, logo: e.target.files[0] }))} // Change to handle the file directly

                        />
            </div>         
          
          </div>

              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditAgency;
