import { useNavigate } from "react-router-dom";
import  jwtDecode  from "jwt-decode"
 // Function to check if the token is expired
 const isTokenValid = () => {
    const token = localStorage.getItem('auth_token');
    
    if (!token) {
      return false; 
    }
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp > currentTime;
    } catch (error) {
      return false; 
    }
  };

export default isTokenValid