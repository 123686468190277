import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Dropdown from './dropdown';
import Services from './services';
import Pagination from '../../components/pagination';
import utils from '../../helpers/textEdit';
import useFetch from '../../helpers/fetch';

const Index = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0); // New state for refresh
  
  // Fetch estates with refreshKey dependency
  const url = `${config.api_path}/estates?page=${currentPage}`;
  const { data: estates, loading, error } = useFetch(url, refreshKey); // Pass refreshKey to useFetch
  console.log(estates);

  useEffect(() => {
    if (estates) {
      setTotalPages(estates.meta.last_page);
      setCurrentPage(estates.meta.current_page);
    }
    document.title = "Tuwe | Estates";
  }, [estates]);

  useEffect(() => {
    if (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }, [error]);

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const refreshData = () => {
    setRefreshKey(prevKey => prevKey + 1); // Update refreshKey to trigger refresh
  };

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Residential Estates</h3>
            <Link to="/estates/create">
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Estate
              </button>
            </Link>
            
            {loading?(
               <Spinner />
            ) : (
              <>
                <span style={{ display: 'block', marginBottom: '10px' }}>
                  {"Viewing page " + currentPage + " of " + totalPages + " pages"}
                </span>
                <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th></th>
                      <th>Name</th>
                      <th>District</th>
                      <th>Village</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {estates && estates.data.length > 0 ? (
                      estates.data.map((estate, index) => (
                        <tr key={estate.id}>
                          <td>{(currentPage - 1) * 10 + index + 1}</td>
                          <th>
                            <img className="img-xs rounded-circle" src={estate.image} alt="estate" />
                          </th>
                          <td>
                            <Link to={`/estates/${estate.id}`}>
                              {utils.shorten_string(estate.name, 30)}
                            </Link>
                          </td>
                          <td>{estate.district?.name || 'N/A'}</td>
                          <td>{utils.shorten_string(estate.village?.name, 10) || 'N/A'}</td>
                          <td>{utils.statusDisplay(estate.status)}</td>
                          <td>
                            <Dropdown 
                              estates={estate} 
                              onDelete={refreshData} // Pass refreshData to trigger refresh
                            />
                          </td>
                          <td>
                            <Services estates={estate} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No estates found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
               </div>
                <Pagination 
                  currentPage={currentPage} 
                  totalPages={totalPages} 
                  setCurrentPage={handlePageChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;
