import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Backbtn from '../../components/backbtn';

const View = () => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    document.title = 'Tuwe | Estate';

    const fetchEstateData = async () => {
      try {
        if (!storedToken) {
          toast.error('Authentication token is missing. Please log in again.');
          return;
        }

        const response = await axios.get(`${config.api_path}/estate-bodas/${id}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setFormData(response.data.data);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch estate data. Please try again.');
        setLoading(false);
      }
    };

    fetchEstateData();
  }, [id, storedToken]);

  const formatKey = (key) => {
    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Layout>
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <Backbtn />
            <h2>{formData.full_name || 'Estate Boda Profile'}</h2>
            
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <div className="profile-content">
              <div className="profile-info">
                <div className="image-section">
                  <img 
                    src={formData.passport_photo || '/images/placeholder.png'}  
                    alt={formData.name || 'Profile Image'} 
                    className="profile-image"
                  />

                  {formData.about && (
                    <div className="about-section">
                      <h3>About</h3>
                      <p>{formData.about}</p>
                    </div>
                  )}

                  <div className="download-section" style={{ marginTop: '20px' }}>
                    <h5>Download Documents</h5>
                    <div style={{ display: 'flex' }}>
                      <a href={formData.local_council_letter} target='_blank' rel='noreferrer'>
                        <button className="btn btn-primary" style={{ marginRight: '20px' }}>
                          Local Council Letter
                        </button>
                      </a>
                      <a href={formData.community_police_letter} target='_blank' rel='noreferrer'>
                        <button className="btn btn-secondary">
                          Police Letter
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="details-section">
                  <table className="table details-table">
                    <tbody>
                      {Object.keys(formData).length > 0 ? (
                        Object.entries(formData)
                          .filter(([key]) => !['id', 'passport_photo', 'community_police_letter', 'local_council_letter', 'boda_stage_id', 'customer_id'].includes(key))
                          .map(([key, value]) => (
                            <tr key={key}>
                              <td className="detail-key">{formatKey(key)}</td>
                              <td className="detail-value">
                                {typeof value === 'object' && value !== null ? 
                                  (value.name || 'N/A') : // Display name if nested object
                                  value?.toString() || 'N/A'
                                }
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="2">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default View;
