import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../components/pagination'
import Backbtn from '../../components/backbtn';
import utils from '../../helpers/textEdit'

const Members = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('auth_token');
  const estateID = localStorage.getItem('estateID');

  // Function to fetch estates
  const fetchMembers = (page) => {
    setLoading(true);
    axios.get(`${config.api_path}/customers?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setMembers(response.data.filter((item)=>item.estate_id == estateID));
      setTotalPages(1); // Get total pages from response
      setCurrentPage(1); // Update current page
      setLoading(false);
    })
    .catch((error) => {
      toast.error('Failed to fetch members. Please try again.');
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = "Tuwe | Estates Members";
    setLoading(true); // Start loading
    fetchMembers(currentPage); // Fetch estates on component mount and when page changes
  }, [currentPage]);

  console.log("members",members)

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>Estates Members</h3>
             <Backbtn />

            {loading ? (
              <Spinner />
            ) : (
              <>
                <span style={{ display: 'block', marginBottom: '10px' }}>
                  {"Viewing page " + currentPage + " of " + totalPages + " pages"}
                </span>
                <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members? (
                      members.map((person, index) => (
                        
                        <tr key={person.id}>
                          <td>{(currentPage - 1) * 10 + index + 1}</td> {/* Adjust index for pagination */}
                          <td>
                            <Link to={`/estates-member/${person.id}`}>{person.name}</Link>
                          </td>
                          <td>{person.email}</td>
                          <td>{person.phone_number}</td>
                          <td>
                            <Dropdown member={person} onDelete={() => fetchMembers(currentPage)} /> {/* Pass currentPage to fetchmembers */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No estates member found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
                <Pagination currentPage={currentPage} 
                            totalPages={totalPages} 
                            setCurrentPage={setCurrentPage}
                 />
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Members;
