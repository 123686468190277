import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../../../config';

// Custom Dropdown Component
const Index = ({ user, onDisable }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Function to close dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest(`#dropdown-${user.id}`)) {
          setIsOpen(false);
        }
      };

      // Add event listener to close dropdown on outside click
      document.addEventListener('click', handleClickOutside);
      
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [user.id]);

    // Function to handle user disable
    const handleDisableUser = async () => {
      const confirmed = window.confirm('Are you sure you want to disable this user?');
      if (confirmed) {
        const token = localStorage.getItem('auth_token'); // Assuming the token is stored in localStorage
        try {
          const response = await axios.delete(config.api_path+`/users/${user.id}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token in the Authorization header
            },
          });

          if (response.status === 200) {
            toast.success('User disabled successfully!'); // Show success toast
            onDisable(user.id); // Call the function to update the user list in the parent component
          } else {
            toast.error('Failed to disable user. Please try again.'); // Show error toast
          }
        } catch (error) {
          console.error('Error disabling user:', error);
          toast.error('An error occurred while disabling the user.'); // Show error toast
        }
      }
    };

     // Function to handle user disable
     const handleEnableUser = async () => {
      const confirmed = window.confirm('Are you sure you want to enable this user?');
      if (confirmed) {
        const token = localStorage.getItem('auth_token'); // Assuming the token is stored in localStorage
        try {
          const response = await axios.post(config.api_path+`/users/${user.id}/restore`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token in the Authorization header
            },
          });

          if (response.status === 200) {
            toast.success('User enabled successfully!'); // Show success toast
            onDisable(user.id); // Call the function to update the user list in the parent component
          } else {
            toast.error('Failed to enabled user. Please try again.'); // Show error toast
          }
        } catch (error) {
          console.error('Error enabled user:', error);
          toast.error('An error occurred while enabled the user.'); // Show error toast
        }
      }
    };

    return (
      <div className="dropdown" id={`dropdown-${user.id}`} style={{ position: 'relative' }}>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          Actions
        </button>
        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', position: 'absolute', top: '100%', zIndex: 1 }}>
          <Link className="dropdown-item" to={`/users/${user.id}/edit`}>Edit</Link>
          <button className="dropdown-item" onClick={handleDisableUser}>
            Disable
          </button>
          <Link className="dropdown-item" onClick={handleEnableUser} to="#">
            Enable
          </Link>
        </div>
      </div>
    );
};

export default Index;
