import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config';
import Spinner from '../../components/spinner';
import Dropdown from './dropdown';
import Pagination from '../../components/pagination';
import Backbtn from '../../components/backbtn';
import utils from '../../helpers/textEdit'

const Index = () => {
  const estateID = localStorage.getItem('estateID');
  const estateName = localStorage.getItem('estateName');
  const [estateBodaStage, setEstateBodaStage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('auth_token');
  const itemsPerPage = 15;

  const fetchEstateBodaStage = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.api_path}/estates/${estateID}/boda-stages?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Set the fetched data and total count from response
      setEstateBodaStage(response.data.boda_stages);
      setTotal(response.data.boda_stages.length || 0); // Assuming total is in response.meta
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch estate Boda Stage. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Tuwe | Estate Boda Stage";
    fetchEstateBodaStage(currentPage);
  }, [currentPage]);

  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <Layout>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3>{utils.capitalizeWords(estateName)} Boda Stages</h3>
            <Backbtn />
            <span>{"Viewing page " + currentPage + " of " + totalPages + " pages (" + total + " results)"}</span>
            <Link to="/estate-boda-stage/create">
              <button
                type="button"
                style={{ marginBottom: '10px', float: 'right' }}
                className="btn btn-outline-primary"
              >
                New Boda Stage
              </button>
            </Link>

            {loading ? (
              <Spinner />
            ) : (
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Stage Name</th>
                    <th>Leaders Name</th>
                    <th>Mobile</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {estateBodaStage.length > 0 ? (
                    estateBodaStage.map(stage => (
                      <tr key={stage.id}>
                        <td>{stage.id}</td>
                        <td><Link to={`/estate-boda/${stage.id}`}>{utils.capitalizeWords(stage.name)}</Link></td>
                        <td>{utils.capitalizeWords(stage.leader_name)}</td>
                        <td>{stage.leader_phone_number}</td>
                        <td>{stage.location.substring(0, 20) + "..."}</td>
                        <td>{ utils.statusDisplay(stage.status) }</td>
                        <td>
                          <Dropdown stageId={stage.id} onDelete={() => fetchEstateBodaStage(currentPage)} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">No boda stages found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
            )}

            <Pagination 
              currentPage={currentPage} 
              totalPages={totalPages} 
              setCurrentPage={setCurrentPage} 
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Index;

